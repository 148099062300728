import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n' //多语言切换
import Element from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import enLocale from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/element-variables.scss'

// import './utils/elementui'
import Vuex from 'vuex'
import LazySlideCaptcha from 'lazy-slide-captcha' // 图形验证
import 'lazy-slide-captcha/dist/lazy-slide-captcha.css' // 图形验证
import 'flag-icon-css/css/flag-icons.css'
import '@/assets/css/base.scss' //公共样式
import '@/assets/css/dark.css' //暗夜版样式
import './utils/rem'
import './mock/index' //数据模拟
import { deleteUrlParamsAndReplaceUrl, useSearch } from '@/utils/tools'
import { logoutIntercom, updateIntercomInfo } from '@/utils/intercom'
// import * as Sentry from '@sentry/vue'
import { JSCoreGlobalConfig } from 'sm-js-core'

Vue.config.productionTip = false

Vue.use(LazySlideCaptcha) // 图形验证
Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(VueAxios, axios)

// // 生成环境才开启sentry
// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     Vue,
//     dsn: process.env.VUE_APP_ENTRY_KEY,
//     integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   })
// }

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: Object.assign(require('./assets/languages/en.json'), enLocale)
  }
})
locale.use(lang)
Vue.use(Element, { i18n: (key, value) => i18n.t(key, value) }, { size: 'small', zIndex: 3000 })
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
router.beforeEach((to, from, next) => {
  console.log(to)
  if (
    to.name !== '/' &&
    to.name !== 'login' &&
    to.name !== 'storeLogin' &&
    to.name !== 'register' &&
    to.name !== 'password' &&
    to.name !== 'verificationCode' &&
    to.name !== 'loading' &&
    to.name !== 'u' &&
    to.name !== 'marketing' &&
    to.name !== 'adsmarketing' &&
    to.name !== 'StepOne' &&
    to.name !== 'StepTwo' &&
    to.name !== 'NewStepTwo' &&
    to.name !== 'Mpassword' &&
    to.name !== 'captcha' &&
    to.name != 'Airwallexsuccess'
  ) {
    const token = useSearch('tk')
    if (token) {
      sessionStorage.setItem('Authorization', token)
      sessionStorage.setItem(
        'USAdrop_user',
        JSON.stringify({
          ExpiresIn: 3600,
          RefreshToken: token,
          Success: true,
          Token: token,
          TokenType: 'Bearer',
          expiresTime: parseInt(`${new Date().getTime() / 1000}`) + 3600
        })
      )
      deleteUrlParamsAndReplaceUrl(['tk'])
      next()
    } else {
      if (localStorage.getItem('Authorization') || to.name === '404') {
        if (localStorage.getItem('Authorization') && !sessionStorage.getItem('Authorization')) {
          // 存储方式
          sessionStorage.setItem('Authorization', localStorage.getItem('Authorization'))
          sessionStorage.setItem('USAdrop_user', localStorage.getItem('USAdrop_user'))
        }
      }
      next()
    }
  } else {
    next()
  }
  if (
    to.name === 'login' ||
    to.name === 'register' ||
    to.name === 'StepOne' ||
    to.name === 'captcha' ||
    to.name === 'verificationCode' ||
    to.name === 'adsmarketing' ||
    to.name === 'password' ||
    to.name === 'loading' ||
    to.name === 'storeLogin' ||
    to.name === 'Airwallexsuccess'
  ) {
    logoutIntercom()
  } else {
    updateIntercomInfo()
  }
})

JSCoreGlobalConfig.getInstance().setOptions({
  ossBaseUrl: process.env.VUE_APP_BASE_API + '/goodsapi'
})

Vue.prototype.globalClick = function (callback) {
  //页面全局点击
  let temp = document.getElementsByTagName('body')[0]
  temp.onclick = callback
  // console.log(temp.onclick);
}
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
