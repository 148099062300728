import { fabric } from 'fabric'

export function designRender(workspace, designStages) {
  return new Promise((resolve, reject) => {
    if (!designStages) {
      return reject(new Error('No design stages provided'))
    }

    if (workspace) {
      workspace.innerHTML = ''
    }

    const canvases = []
    const loadPromises = []

    for (const stageKey in designStages) {
      const stage = designStages[stageKey]
      const canvasElement = document.createElement('canvas')
      canvasElement.width = 400
      canvasElement.height = 400
      canvasElement.className = 'canvas'
      workspace?.appendChild(canvasElement)

      const fabricCanvas = new fabric.Canvas(canvasElement, {
        stopContextMenu: true, // 禁止默认右键菜单
        controlsAboveOverlay: true, // 超出clipPath后仍然展示控制条
        preserveObjectStacking: true // 当选择画布中的对象时，让对象不在顶层。
      })

      const loadPromise = new Promise((resolveLoad, rejectLoad) => {
        fabricCanvas.loadFromJSON(stage, async () => {
          try {
            const objects = fabricCanvas.getObjects()
            const objPromises = objects.map((obj) => {
              if (obj.type.toLowerCase().includes('image') && obj.getSrc) {
                const imgSrc = obj.getSrc()
                return new Promise((resolveImageLoad, rejectImageLoad) => {
                  fabric.Image.fromURL(
                    imgSrc,
                    (img) => {
                      if (img) {
                        img.set({
                          crossOrigin: 'anonymous',
                          hasControls: false,
                          hasBorders: false,
                          selectable: false,
                          lockMovementX: true,
                          lockMovementY: true,
                          lockScalingX: true,
                          lockScalingY: true,
                          evented: false
                        })
                        obj.setElement(img.getElement())
                        resolveImageLoad()
                      } else {
                        rejectImageLoad(new Error(`Failed to load image: ${imgSrc}`))
                      }
                    },
                    { crossOrigin: 'anonymous' }
                  )
                })
              } else {
                obj.set({
                  crossOrigin: 'anonymous',
                  hasControls: false,
                  hasBorders: false,
                  selectable: false,
                  lockMovementX: true,
                  lockMovementY: true,
                  lockScalingX: true,
                  lockScalingY: true,
                  evented: false
                })
                if (obj.id === 'edit_zone') {
                  obj.opacity = 0
                }
                // 调整文本对象的字体大小
                if (obj.type.toLowerCase().includes('text')) {
                  const originalObject = stage.objects.find((item) => item.id === obj.id)
                  if (originalObject) {
                    adjustFontSizeToFitWidth(
                      obj,
                      originalObject.width || originalObject.clipPath?.width || canvasElement.width
                    )
                  }
                }
                return Promise.resolve()
              }
            })

            await Promise.all(objPromises)
            fabricCanvas.requestRenderAll()
            resolveLoad()
          } catch (error) {
            rejectLoad(error)
          }
        })
      })

      loadPromises.push(loadPromise)
      canvases.push({
        canvas: fabricCanvas,
        stageKey: stageKey
      })
    }

    Promise.all(loadPromises)
      .then(() => {
        const images = []
        canvases.forEach((canvas) => {
          images.push({
            image: canvas.canvas.toDataURL({
              enableRetinaScaling: true,
              multiplier: 5
            }),
            stageKey: canvas.stageKey
          })
        })
        resolve(images)
      })
      .catch(reject)
  })
}

function adjustFontSizeToFitWidth(textObject, maxWidth) {
  let fontSize = textObject.fontSize
  let textWidth = textObject.width
  while (textWidth > maxWidth && fontSize > 0) {
    fontSize -= 1
    textObject.set({ fontSize })
    textObject.setCoords()
    textWidth = textObject.width
  }
}
