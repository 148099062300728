<!--
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
-->
<!--
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
-->
<template>
  <div class="container">
    <div class="headerBg_Img">
      <img src="../../assets/imgs/NewLogo/LogoOne.png" alt="">
    </div>
    <div class="loading_text">
      <img src="@/assets/imgs/succeed.png" alt="">
    </div>
      <div class="successful">
        Payment successful
      </div>
      <div class="Confirm_btn">
        <button @click="ConfirmBtn">Confirm</button>
      </div>
  </div>
</template>
<script>
  export default {
    data: () => {
      return {
      };
    },
    methods: {
      ConfirmBtn() {
        /* this.$router.push('TopUpHistory') */
        window.location.href = `${window.location.origin}/TopUpHistory`
      }
    }
  };
</script>

<style scoped>
.successful {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #AAAAAA;
  text-align: center;
  margin-top: 57px;
}
.headerBg_Img {
  margin-top: 50px;
  margin-left: 80px;
}
.headerBg_Img img{
  width: 130px;
  height: 130px;
}
.loading_text {
  text-align: center;
  margin-top: 20px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.loading_text img {
  width: 350px;
  height: 250px;
}
.Confirm_btn {
  text-align: center;
}
.Confirm_btn button {
  border: 0;
  margin-top: 50px;
  width: 180px;
  height: 45px;
  line-height: 40px;
  background: #15104B;
  color: #fff;
  border-radius: 50px;
  font-size: 18px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Confirm_btn button:hover {
  background: #3C3772;
  color: #fff;
}
</style>
