<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
      <el-tabs v-model="activeName" style="padding-bottom: 10px;">
        <el-tab-pane :label="$t('')" name="first">
          <div class="bigbox">
            <div class="box" v-for="item in options" :key="item.index">
              <iframe width="100%" height="100%"
                :src="item.VideoPath"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
              </iframe>
              <div class="synopsis">{{ item.Title }}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="pagebox">
        <PagePagination
          :total="options1.total"
          :page-size="options1.pageSize"
          :current-page="options1.page"
          @current-change="pageChange" />
    </div>
  </div>
</template>
<script>
import PagePagination from '@/components/PagePagination.vue';
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      dispatch: null,
      page: "video",
      activeName: "first",
      currentPage: 1,
      options1: 
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 8,
          total: 0, //总条数
          jumpPage: 1, //跳转页数
        },
      options: [],
    };
  },
  components: {
    PagePagination
  },
  computed: {
    ...mapState(["theme", "userInfo"]),
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getData();
  },
  methods: {
    pageChange(page) { //分页器页数改变
      this.options1.page = page;
      this.getData();
    },
    getPageList() { //分页器跳转页数
      this.options1.page = this.options1.jumpPage > 0 ? Number(this.options1.jumpPage) : 1;
      this.getData();
    },
    VideoDetailBtn() {
      this.$router.push("AllVideos");
    },
    getData() {
      let that = this;
      let type = Number(that.activeName);
      that.dispatch('video/GetVideoListVi', {
        CurrentPageIndex: that.options1.page,
        PageSize: that.options1.pageSize,
        RecordCount: that.options1.list.length
      })
      .then((res) => {
        that.options = res.Items;
        this.options1.total = res.TotalItems
      })
      .catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
}
.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
::v-deep .el-tabs__nav-scroll {
  height: 0;
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
}
/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background: #6788ff;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: rgb(255 255 255 / 50%);
}
/* 按钮 */
.activeName .orders {
  position: absolute;
  top: 20px;
  right: 32px;
  width: 178px;
  height: 40px;
  background: linear-gradient(132deg, #34c2eb, #69d3e3);
  border-radius: 10px;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  border: 0;
}
.bigbox {
  display: flex;
  flex-wrap: wrap;
}
/* box */
.box {
  margin-top: 66px;
  margin-left: 32px;
  width: 395px;
  height: 250px;
  background: #f6f6fa;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
}
/* 视频 */
.box .video {
  padding-top: 50px;
  margin-left: 24px;
  border-radius: 10px !important;
}
/* 简介 */
.synopsis {
  margin-top: 3px;
  margin-left: 24px;
  width: 342px;
  height: 50px;
  line-height: 80px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #191919;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  display: -webkit-box;
 -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 分页 */
.pagebox {
  width: 1320px;
  margin-top: 30px;
}
</style>
