var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "store" },
    [
      _vm.StoreWelcome ? _c("div") : _vm._e(),
      _c(
        "div",
        {
          staticClass: "particulars",
          class: [_vm.theme === "common" ? "" : "dark"],
        },
        [
          _c(
            "div",
            { staticClass: "btn_box" },
            [
              _vm.paymentList.IsAdmin == true
                ? _c(
                    "el-button",
                    {
                      staticClass: "orders",
                      attrs: { type: "success", circle: "" },
                      on: { click: _vm.Added },
                    },
                    [_vm._v(_vm._s(_vm.$t("store.orders")) + " ")]
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://shopify.pxf.io/rQvo6d",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "If you don't have a Shopify store, click this button to create one quickly.",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("p", { staticClass: "comBtn linkStore btn-bg2" }, [
                        _vm._v(" Bulid your shopify store "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm.paymentList.IsAdmin == true
                ? _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "Non-Shopify stores can process orders here or contact sales agents.",
                        placement: "top-start",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "comBtn AddOfflineStore btn-bg5",
                          on: { click: _vm.AddOfflineStore },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("store.AddOtherStore")) + " "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.paymentList.IsAdmin == true && _vm.showAddTikTokBtn == true
                ? _c(
                    "el-button",
                    {
                      staticClass: "add-tt-btn",
                      attrs: {
                        loading: _vm.addTTLoading,
                        type: "success",
                        circle: "",
                      },
                      on: { click: _vm.ttAdded },
                    },
                    [_vm._v(_vm._s(_vm.$t("store.addTikTokStore")) + " ")]
                  )
                : _vm._e(),
              _vm.InventoryListBtn
                ? _c(
                    "el-button",
                    {
                      staticClass: "Inventorylist",
                      on: { click: _vm.InventorylistBtn },
                    },
                    [_vm._v(_vm._s(_vm.$t("Inventorylist.Inventorylist")))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "activeName" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      staticClass: "first",
                      attrs: { label: _vm.$t("store.settings"), name: "first" },
                    },
                    [
                      _vm.StoreList && _vm.StoreList.length > 0
                        ? [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.StoreList },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "StoreName",
                                    align: "center",
                                    label: _vm.$t("store.name"),
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: _vm.$t("store.status"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return _c(
                                            "div",
                                            { staticClass: "content" },
                                            [
                                              scope.row.StoreType !== "Offline"
                                                ? _c("span", [
                                                    scope.row.Status === 1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "bakingpackaging",
                                                            staticStyle: {
                                                              color: "#6788ff",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "store.Authorized"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    scope.row.Status === -1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "bakingpackaging",
                                                            staticStyle: {
                                                              color: "#e6a23c",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "store.Unauthorized"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    scope.row.Status === -2
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "bakingpackaging",
                                                            staticStyle: {
                                                              color: "#ff6767",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "store.Unauthorized"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    scope.row.Status === -3
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "bakingpackaging",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "store.Unauthorized"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                : _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#6788ff",
                                                        },
                                                      },
                                                      [_vm._v("Non-Shopify")]
                                                    ),
                                                  ]),
                                            ]
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1425112080
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "CreateDate",
                                    align: "center",
                                    label: _vm.$t("store.authorization"),
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: _vm.$t("store.actions"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return _c(
                                            "div",
                                            {},
                                            [
                                              scope.row.StoreType !== "Offline"
                                                ? [
                                                    scope.row.StoreType ===
                                                    "Tiktok"
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "status comBtn col-col5 border-col5",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.ttAdded(
                                                                      true
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "store.reauthorize"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      : [
                                                          _vm.paymentList
                                                            ?.AddStoreForHand !==
                                                          true
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "status comBtn col-col5 border-col5",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.actions(
                                                                          scope
                                                                            .row
                                                                            .ApiColumn1
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "store.reauthorize"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3993813955
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        : _c("el-empty", { attrs: { description: "No data" } }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogFormOffline },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormOffline = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form } },
                [
                  _c("div", { staticClass: "Add-Store" }, [
                    _vm._v(_vm._s(_vm.$t("store.AddOther"))),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "Store",
                      attrs: { "label-width": _vm.formLabelWidth },
                    },
                    [
                      _c("span", { staticClass: "Name" }, [
                        _vm._v(_vm._s(_vm.$t("store.store"))),
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("store.pleaseLink"),
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "NoShopifyStoreTitle" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("store.AddOtherStoreLife")))]),
              ]),
              _c("div", { staticClass: "dialog-footer" }, [
                _c("div", { staticClass: "footerbox" }, [
                  _c(
                    "button",
                    {
                      staticClass: "Cancel btn_com comBtn col-col0 border-col0",
                      on: { click: _vm.Cancel },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("store.cancel")) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "Save btn_com comBtn btn-bg0 border-col0",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmOfflineStore(_vm.form.name)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("store.save")) + " ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-shopify-store-dialog", {
        on: {
          onAddSuccess: function ($event) {
            return _vm.getData()
          },
        },
        model: {
          value: _vm.showAddShopifyStore,
          callback: function ($$v) {
            _vm.showAddShopifyStore = $$v
          },
          expression: "showAddShopifyStore",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }