<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName_big_box">
      <div class="activeName">
        <div class="photograph">
          <div class="Balance_Box">
            <div class="Balance_upgrade_box">
              <div class="Balance_word" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
                <p>{{ $t("Alliance.Balance") }} : </p>
              </div>
              <div
                  class="upgrade"
                  @click="DiamondsMember"
                  v-if="paymentList.GradeId == 1 || paymentList.GradeId == 2"
                >
                  <span>{{ $t("Alliance.upgrade") }}</span>
                  <img src="../../assets/imgs/UpgradeToPro/MemberJianTouRed.png" alt="" />
              </div>
            </div>
            <div class="AllianceMessage_CommissionEarned">
              <p>
                $ {{ parseFloat(AllianceMessage.CommissionBalance).toFixed(2) }}
              </p>
            </div>
            <div class="AllianceMessage_CommissionEarned_affiliate">
              <p>
                {{ $t("Alliance.earnedTotal") }}
                <span>${{ parseFloat(AllianceMessage.CommissionEarned).toFixed(2) }}</span>
                {{ $t("Alliance.affiliateMarketing") }}
              </p>
            </div>
            <div v-if="paymentList.IsAdmin == true" @click="Withdraw1" class="Alliance_withdraw comBtn col-col0">
              {{ $t("Alliance.withdraw") }}
            </div>
          </div>
          <div class="Total_Box">
            <div class="Total_word">
              <p>Total : </p>
            </div>
            <div class="Total_box_AffiliateBalance">
              <div class="AllianceMessage_CommissionEarned_Total">
                <p>$ {{ parseFloat(AllianceMessage.CommissionEarned).toFixed(2) }}</p>
              </div>
              <div class="Affiliate_TopLine">
                <img src="../../assets/imgs/Affiliate/TopLine.png" alt="">
                <img src="../../assets/imgs/Affiliate/BottomLine.png" alt="">
              </div>
              <div>
                <div class="Alliance_FulfillmentEarned" @mouseover="handleMethodsMemberRate" @mouseleave="handelLeaveMemberRate">
                  <span>{{ $t("Alliance.MembershipEarned") }}</span>
                  <span>&nbsp;$&nbsp;{{ parseFloat(AllianceMessage.CommissionUpgradeEarned).toFixed(2) }}</span>
                </div>
                <div class="memberRate" v-if="ShowMemberRate">
                  <span>{{ $t("Alliance.MembershipRate") }}</span>
                  <span>{{ AllianceMessage.UpgradeRate * 100 }}%</span>
                </div>
                <div class="Alliance_MembershipEarned" @mouseover="handleMethodsRate" @mouseleave="handelLeaveRate">
                  <span>{{ $t("Alliance.FulfillmentEarned") }}</span>
                  <span>&nbsp;${{ parseFloat(AllianceMessage.CommissionRechargeEarned).toFixed(2) }}</span>
                </div>
                <div class="fullRate" v-if="showFullRate">
                  <span>{{ $t("Alliance.FulfillmentRate") }}</span>
                  <span>{{ AllianceMessage.CommissionRate * 100 }}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="searchbox">
          <div>
            <div class="Link">{{ $t("Alliance.link") }}</div>
            <div class="search_input">
              <el-input
                type="text"
                v-model="Url"
                :placeholder="$t('Alliance.url')"
                :disabled="true"></el-input>
            </div>
            <button
              @click="copy"
              :data-clipboard-text="Url"
              class="search_btn comBtn btn-bg0"
              id="copy_text">
              {{ $t("Alliance.copy") }}
            </button>
          </div>
          <div>
            <div class="Link_Affiliate_Code">{{ $t("Alliance.copyAllianceCode") }}</div>
            <div class="Link_Affiliate_Code_input">
              <el-input
                type="text"
                v-model="AffiliateCodeUrl"
                :placeholder="$t('Alliance.url')"
                :disabled="true"
              ></el-input>
            </div>
            <button
              @click="copyAllianceCode"
              :data-clipboard-text="AffiliateCodeUrl"
              class="Affiliate_Code_btn comBtn btn-bg0"
              id="copy_text"
            >
              {{ $t("Alliance.copy") }}
            </button>
          </div>
        </div>
        <div class="activeName1">
          <el-tabs v-model="activeName">
            <el-tab-pane label="Commission Record" name="first">
              <template v-if="AllianceList && AllianceList.length > 0">
                <el-table :data="AllianceList" style="width: 100%">
                  <el-table-column prop="Referee" align="center" :label="$t('Alliance.rferee')">
                  </el-table-column>
                  <el-table-column prop="CreateTime" align="center" :label="$t('Alliance.time')">
                  </el-table-column>
                  <el-table-column
                    prop="AffiliateStype"
                    align="center"
                    :label="$t('Alliance.AffilateStype')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="CommissionRate"
                    align="center"
                    :label="$t('Alliance.rate')"
                  >
                  </el-table-column>
                  <el-table-column align="center" :label="$t('Alliance.commission')">
                    <template slot-scope="scope"> $&nbsp;{{ scope.row.Commission }} </template>
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <PagePagination
                    :total="optionList.total"
                    :page-size="optionList.pageSize"
                    :current-page="optionList.page"
                    @current-change="pageChange"
                  />
                </div>
              </template>
              <el-empty description="No data" v-else></el-empty>
            </el-tab-pane>
            <el-tab-pane label="Withdrawal History" name="two">
              <template v-if="AllianceWithdrawal && AllianceWithdrawal.length > 0">
                <el-table :data="AllianceWithdrawal" style="width: 100%">
                  <el-table-column prop="CreateTime" align="center" :label="$t('Alliance.time')">
                  </el-table-column>
                  <el-table-column prop="Amount" align="center" :label="$t('Alliance.amount')">
                  </el-table-column>
                  <el-table-column align="center" :label="$t('Alliance.status')">
                    <div slot-scope="scope" class="success">
                      <span
                        v-if="scope.row.Status == -1"
                        style="font-size: 14px; color: #f81b17"
                        class="bakingpackaging"
                        >{{ $t("Alliance.refuse") }}</span>
                      <span
                        v-if="scope.row.Status == 0"
                        style="font-size: 14px; color: #6788ff; opacity: 0.8"
                        class="bakingpackaging"
                        >{{ $t("Alliance.wait for") }}</span>
                      <span
                        v-if="scope.row.Status == 1"
                        style="font-size: 14px; color: #f81b17; opacity: 0.8"
                        class="bakingpackaging"
                        >{{ $t("Alliance.sucessed") }}</span>
                    </div>
                  </el-table-column>
                </el-table>
                <div class="pagebox">
                  <PagePagination
                    :total="WithdrawalList.total"
                    :page-size="WithdrawalList.pageSize"
                    :current-page="WithdrawalList.page"
                    @current-change="pageChange"
                  />
                </div>
              </template>
              <el-empty :description="$t('Alliance.NoData')" v-else></el-empty>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="paybox" v-if="Withdrawn1">
      <div class="pay_content">
        <p class="pay_title">{{ $t("Alliance.withdrawal") }}<span @click="btn"></span></p>
        <div class="pay_items">
          <div class="pay_item">
            <div class="pay_left">{{ $t("Alliance.type") }}</div>
            <div class="pay_right">
              <div class="time_slot">
                <el-select
                  v-model="type"
                  placeholder=""
                  style="
                    width: 350px;
                    height: 40px;
                    margin-top: 10px;
                    font-size: 13px;
                    font-family: Regular;
                    -webkit-font-smoothing: antialiased;">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                    style="
                      width: 350px;
                      border: #ccc;
                      outline: none;
                      font-size: 13px;
                      font-family: Regular;
                      -webkit-font-smoothing: antialiased;">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="pay_item">
            <div class="pay_left_account">{{ $t("Alliance.account") }}</div>
            <div class="pay_right_account">
              <el-input
                class="amount_account"
                v-model="account"
                :placeholder="$t('Alliance.number')"
              ></el-input>
            </div>
          </div>
          <div class="pay_item">
            <div class="pay_left_account">{{ $t("Alliance.amount") }}</div>
            <div class="pay_right_account">
              <el-input
                class="amount"
                v-model="withdraw"
                :placeholder="$t('Alliance.sum3')"
              ></el-input>
            </div>
          </div>
          <div class="minimum_withdrawal">
            <span>
              <i class="el-icon-question"></i>
            </span>
            <span>{{ $t("Alliance.TheMinimum") }}</span>
          </div>
          <div class="pay_item">
            <button
              v-if="AllianceMessage.CommissionBalance >= 0"
              class="paybtn btn-bg0"
              @click="confirm">
              {{ $t("Alliance.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PagePagination from "@/components/PagePagination.vue";
import Clipboard from "clipboard";
import { mapState } from "vuex";
import Cookies from "js-cookie";
export default {
  data: () => {
    return {
      dispatch: null,
      page: "Alliance",
      activeName: "first",
      currentPage: 1,
      Withdrawn1: false,
      AllianceMessage: [], //营销联盟信息
      AllianceList: [], // 佣金列表
      AllianceWithdrawal: [], // 佣金提现列表
      paymentList: [],
      more: false,
      withdraw: "", //提现金额
      account: "", // 体现账号
      url: "https://app.usadrop.com/", // 搜索
      Url: "",
      AffiliateCodeUrl: "",
      ShopShow: false,
      type: "", //提现类型
      options: [
        {
          id: 1,
          value: "PayPal",
          label: "PayPal",
        },
        {
          id: 2,
          value: "Bank Transfer",
          label: "Bank Transfer",
        },
      ],
      optionList:
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1, //跳转页数
        },
      WithdrawalList:
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1, //跳转页数
        },
        showFullRate: false,
        ShowMemberRate: false,
        AllianceMessageCommissionBalance: false
    };
  },
  components: {
    PagePagination,
  },
  created() {
    this.dispatch = this.$store.dispatch;
  },
  mounted() {
    this.getData();
    this.gettingData();
    this.withdrawalData();
    this.menterData();
  },
  computed: mapState(["userInfo", "theme"]),
  methods: {
    onMouseOver() {
      this.AllianceMessageCommissionBalance = true;
    },
    onMouseOut() {
      this.AllianceMessageCommissionBalance = false;
    },
    handleMethodsRate() {
      this.showFullRate = true;
    },
    handelLeaveRate() {
      this.showFullRate = false;
    },
    handleMethodsMemberRate() {
      this.ShowMemberRate = true;
    },
    handelLeaveMemberRate() {
      this.ShowMemberRate = false;
    },
    pageChange(page) { // 分页器页数改变
      this.optionList.page = page;
      this.WithdrawalList.page = page;
      this.gettingData();
      this.withdrawalData();
    },
    getPageList() { // 分页器跳转页数
      this.optionList.page = this.optionList.jumpPage > 0 ? Number(this.optionList.jumpPage) : 1;
      this.WithdrawalList.page =
      this.WithdrawalList.jumpPage > 0 ? Number(this.WithdrawalList.jumpPage) : 1;
      this.gettingData();
      this.withdrawalData();
    },
    menterData() { // 价格重置
      this.dispatch("member/GetMemberVoMe")
        .then((res) => {
          this.paymentList = res.Result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleShopShow() {
      this.ShopShow = !this.ShopShow;
    },
    DiamondsMember() {
      this.$router.push("Upgrade");
    },
    Withdraw1() {
      this.Withdrawn1 = true;
    },
    copy() {
      var _this = this;
      var clipboard = new Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        this.$message.success(this.$t("Alliance.CopySucceeded"));
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        this.$message.warning(this.$t("Alliance.replication"))
        clipboard.destroy();
      });
    },
    copyAllianceCode() {
      var _this = this;
      var clipboard = new Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        this.$message.success(this.$t("Alliance.CopySucceeded"));
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        this.$message.warning(this.$t("Alliance.replication"))
        clipboard.destroy();
      });
    },
    btn() {
      this.type = "";
      this.withdraw = "";
      this.account = "";
      this.Withdrawn1 = false;
    },
    confirm() {
      if (this.type == "") {
        this.$message.warning(this.$t("Alliance.empty"));
        return;
      }
      if (this.withdraw == "") {
        this.$message.warning(this.$t("Alliance.cannot"));
        return;
      }
      if (this.account == "") {
        this.$message.warning(this.$t("Alliance.enter"));
        return;
      }
      if (this.withdraw < 100) {
        this.$message.warning(this.$t("Alliance.minimum"));
        return;
      }
      if (this.withdraw > this.AllianceMessage.CommissionBalance) {
        this.$message.warning(this.$t("Alliance.insufficient"));
        return;
      }
      this.dispatch("alliance/ApplyWithdrawAi", {
        AccountType: this.type,
        Amount: this.withdraw,
        Account: this.account,
      })
        .then((res) => {
          if (res.Success == true) {
            this.type = "";
            this.withdraw = "";
            this.account = "";
            this.$message.success(this.$t("Alliance.successful"));
            this.Withdrawn1 = false;
            this.withdrawalData();
          } else {
            this.$message.error(res.ErrMsg);
          }
        })
        .catch((err) => {
          this.$message.error(res.ErrMsg);
        });
    },
    getData() {
      this
        .dispatch("alliance/GetCommissionInfoAi")
        .then((res) => {
          this.AllianceMessage = res.Result;
          switch(this.AllianceMessage.FromSite){
            case 'vn.usadrop.com':
            this.Url = this.url + "v?" + "d" + "=" + this.AllianceMessage.MarketingCode;
            break;
            case 'app.usadrop.com':
            default:
            this.Url = this.url + "u?" + "d" + "=" + this.AllianceMessage.MarketingCode;
            break;
          }
          this.AffiliateCodeUrl = this.AllianceMessage.MarketingCode;
          Cookies.set("MarketingCode", this.AllianceMessage.MarketingCode);
          if (this.AllianceMessage.CommissionRate == 0) {
            this.more = true;
          } else {
            this.more = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gettingData() {
      this
        .dispatch("alliance/GetCommissionRecordsByPageAi", {
          CurrentPageIndex: this.optionList.page,
          PageSize: this.optionList.pageSize,
          RecordCount: this.optionList.list.length,
        })
        .then((res) => {
          this.AllianceList = res.Items;
          this.optionList.total = res.TotalItems;
          this.AllianceList.map((item) => {
            item.Referee = "***" + item.Referee.substr(-2);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    withdrawalData() {
      this
        .dispatch("alliance/GetCommissionWithdrawalsByPageAi", {
          CurrentPageIndex: this.WithdrawalList.page,
          PageSize: this.WithdrawalList.pageSize,
          RecordCount: this.WithdrawalList.list.length,
        })
        .then((res) => {
          this.WithdrawalList.total = res.TotalItems;
          this.AllianceWithdrawal = res.Items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  font-family: "Regular";
}
.activeName {
  width: 1320px;
  border-radius: 10px;
  padding-bottom: 32px;
  position: relative;
  font-family: "Regular";
}
.minimum_withdrawal {
  text-align: center;
  margin-top: 50px;
  span {
    font-size: 14px;
  }
  .el-icon-question {
    font-size: 18px;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin posi($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}
.photograph {
  width: 100%;
  height: 218px;
  border-radius: 10px;
  font-size: 16px;
  margin: 0 20px 20px;
  position: relative;
  color: #ffffff;
  display: flex;
  justify-content: space-around;
  .Balance_Box {
    width: 644px;
    height: 215px;
    background: #15104B;
    opacity: 1;
    border-radius: 10px;
    .Balance_upgrade_box {
      display: flex;
      .upgrade {
        color: #3f6aff;
        cursor: pointer;
        margin-top: 22px;
        margin-left: 10px;
        img {
          width: 20px;
          height: 20px;
        }
        &:hover {
          font-size: 18px;
        }
      }
    }
    .Balance_word {
      margin-left: 50px;
      margin-top: 20px;
      p {
        font-size: 22px;
      }
    }
    .AllianceMessage_CommissionEarned {
      text-align: center;
      margin-top: 10px;
      p {
        font-size: 24px;
      }
    }
    .AllianceMessage_CommissionEarned_affiliate {
      text-align: center;
      margin-top: 10px;
      p {
        font-size: 16px;
        color: #BABABA;
      }
    }
    .Alliance_withdraw {
      margin-left: 430px;
      margin-top: 25px;
      width: 180px;
      height: 40px;
    }
  }
  .Total_Box {
    width: 644px;
    height: 215px;
    background: #15104B;
    opacity: 1;
    border-radius: 10px;
    .Total_word {
      margin-left: 50px;
      margin-top: 20px;
      p {
        font-size: 22px;
      }
    }
    .AllianceMessage_CommissionEarned_Total {
      margin-left: 100px;
      margin-top: 20px;
      p {
        font-size: 20px;
      }
    }
    .Total_box_AffiliateBalance {
      display: flex;
      margin-top: 15px;
      position: relative;
    }
    .Affiliate_TopLine {
      position: relative;
    }
    .Affiliate_TopLine img:nth-child(1) {
      width: 150px;
      height: 30px;
      position: absolute;
      top: -10px;
      left: -10px;
    }
    .Affiliate_TopLine img:nth-child(2) {
      width: 150px;
      height: 30px;
      position: absolute;
      top: 50px;
      left: -10px;
    }
    .Alliance_FulfillmentEarned {
      margin-left: 160px;
      margin-top: -20px;
      cursor: pointer;
    }
    .Alliance_MembershipEarned {
      margin-left: 160px;
      margin-top: 65px;
      cursor: pointer;
    }
    .fullRate {
      background: url("../../assets/imgs/Affiliate/rate2.png");
      background-size: contain;
      @extend .flex;
      width: 250px;
      height: 52px;
      @include posi(340px, 95px);
      color: #15104b;
      padding-top: 10px;
    }
    .memberRate {
      background: url("../../assets/imgs/Affiliate/rate2.png");
      @extend .flex;
      background-size: contain;
      width: 250px;
      height: 52px;
      @include posi(340px, 10px);
      color: #15104b;
      padding-top: 10px;
    }
  }
}
.time_slot {
  width: 350px;
  height: 40px;
  position: absolute;
  border: none;
  outline: none;
  margin-top: -5px;
}
::v-deep .time_slot .el-input--suffix .el-input__inner {
  width: 350px !important;
  height: 40px !important;
  border: 1px solid #878787;
  border-radius: 50px;
}
::v-deep .time_slot .el-select .el-input--suffix .el-input__suffix {
  display: none !important;
}
.commission {
  width: 440px;
  height: 200px;
  margin-left: 500px;
  margin-top: 30px;
}
.searchbox {
  width: 100%;
  height: 96px;
  line-height: 96px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
  margin-left: 20px;
  display: flex;
  .Link {
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    margin-left: 75px;
  }
  .search_input {
    display: inline-block;
    width: 400px;
    height: 48px;
    line-height: 48px;
    background: #ffffff;
    margin-left: 30px;
  }
}
::v-deep .search_input .el-input__inner {
  width: 400px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
  padding-left: 32px;
  border: 1px solid rgb(0, 0, 0, 0.4);
  border-radius: 50px;
}
.search_btn {
  width: 100px;
  height: 48px;
  margin-left: 30px;
  font-weight: 500;
  color: #ffffff;
}
.Affiliate_Code_btn {
  width: 100px;
  height: 48px;
  margin-left: 30px;
  font-weight: 500;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
}
.Link_Affiliate_Code {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  color: #999999;
  margin-left: 85px;
}
.Link_Affiliate_Code_input {
  display: inline-block;
  width: 150px;
  height: 48px;
  line-height: 48px;
  background: #ffffff;
  margin-left: 30px;
}
::v-deep .Link_Affiliate_Code_input .el-input__inner {
  width: 150px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
  padding-left: 12px;
  border: 1px solid rgb(0, 0, 0, 0.4);
  border-radius: 50px;
}
.activeName1 {
  width: 100%;
  margin-left: 20px;
  margin-top: 32px;
  background-color: #fff;
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 15px 0;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
::v-deep .el-tabs__nav {
  margin-left: 32px;
}
::v-deep .el-table th,
.el-table tr { /* 标签页头部高度 */
  height: 64px;
}
::v-deep .el-table__row { /* 内容区的高度 */
  height: 81px !important;
}
::v-deep .el-tabs__active-bar { /* 标签页下划线 */
  height: 3px !important;
  background-color: #6788ff;
  border-radius: 10px;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #f9f9f9;
}
::v-deep .el-tabs__item {
  font-size: 16px !important;
}
.el-table::before {
  background: #dbdbe4;
}
.success {
  display: inline-block;
  width: 185px;
  height: 47px;
  line-height: 47px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #6788ff;
  border: 0;
}
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.pay_content {
  width: 700px;
  height: 380px;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  left: calc(50% - 333px);
  top: calc(50% - 175px);
}
.pay_title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #000;
  padding-left: 40px;
  padding-right: 32px;
  background: #ffffffff;
  border-bottom: 1px solid #cccccc;
}
.pay_title span {
  width: 15px;
  height: 15px;
  display: block;
  background: url("../../assets/imgs/close.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 15px;
  cursor: pointer;
}
::v-deep .el-input__inner {
  width: 350px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #878787;
  border-radius: 50px;
  padding-left: 32px;
}
.pay_items {
  width: 100%;
  height: 380px;
  background: #fff;
  padding-top: 10px;
}
.pay_item {
  width: 100%;
  height: 45px;
  line-height: 75px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  color: #585858;
}
.pay_left_account {
  width: 214px;
  height: 40px;
  margin-left: 105px;
}
.pay_right_account {
  width: 214px;
  height: 40px;
  margin-left: -50px;
}
.pay_left,
.pay_right {
  width: 214px;
  height: 40px;
  margin: 0 18px;
}
.pay_left {
  text-align: right;
}
.paybtn {
  border-radius: 25px;
  width: 200px;
  height: 44px;
  margin: 40px auto 0;
  color: #fff;
}
.pagebox { /* 分页切换 */
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  background-color: #fff;
}
</style>
