var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "particulars" }, [
    _c("div", { staticClass: "Bgrd" }),
    _c("div", { staticClass: "activeName" }, [
      _c("div", { staticClass: "guide_FillInYou" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("guide.LinkStore")))]),
      ]),
      _c("div", { staticClass: "Inputshopify" }, [
        _vm._m(0),
        _c("div", { staticClass: "Confirm" }, [
          _c("div", { staticClass: "link_button" }, [
            _c("button", { on: { click: _vm.confirmStore } }, [
              _vm._v(_vm._s(_vm.$t("guide.LInkMyStore"))),
            ]),
          ]),
          _c(
            "a",
            {
              attrs: {
                href: "https://shopify.pxf.io/rQvo6d",
                target: "_blank",
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content:
                      "If you don't have a Shopify store, click this button to create one quickly.",
                    placement: "bottom",
                    "popper-class": "tooltip_width",
                  },
                },
                [
                  _c("div", { staticClass: "bulid_shopify_btn" }, [
                    _vm._v("Bulid your shopify store"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "ConfirmQuestion",
              on: { mouseenter: _vm.enter, mouseleave: _vm.leave },
            },
            [_c("i", { staticClass: "el-icon-question" })]
          ),
          _c(
            "div",
            { staticClass: "skipe_words", on: { click: _vm.CheckGuide } },
            [_vm._v(_vm._s(_vm.$t("guide.skip")))]
          ),
        ]),
        _vm.DisplayNotShopify
          ? _c("div", { staticClass: "Contact" }, [
              _c(
                "p",
                {
                  staticStyle: {
                    "font-family": "Regular",
                    "-webkit-font-smoothing": "antialiased",
                    cursor: "pointer",
                    color: "#4c8bf5",
                    "border-bottom": "1px solid #542d8a",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("guide.NotShopify")) + " ")]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "LinkYourStoreContent" }, [
      _c("p", [
        _vm._v(
          "Link your Shopify store now for a complimentary 7-day Pro membership！"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Img" }, [
      _c("img", {
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715582519023_StepStoreGroup.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }