<template>
  <div class="store">
    <div v-if="StoreWelcome"></div>
    <div class="particulars" :class="[theme === 'common' ? '' : 'dark']">
      <div class="btn_box">
        <el-button
          type="success"
          v-if="paymentList.IsAdmin == true"
          class="orders"
          @click="Added"
          circle
          >{{ $t('store.orders') }}
        </el-button>
        <a href="https://shopify.pxf.io/rQvo6d" target="_blank">
          <el-tooltip
            class="item"
            effect="dark"
            content="If you don't have a Shopify store, click this button to create one quickly."
            placement="top-start">
            <p class="comBtn linkStore btn-bg2">
              Bulid your shopify store
            </p>
          </el-tooltip>
        </a>
        <el-tooltip
          class="item"
          effect="dark"
          v-if="paymentList.IsAdmin == true"
          content="Non-Shopify stores can process orders here or contact sales agents."
          placement="top-start">
          <p @click="AddOfflineStore" class="comBtn AddOfflineStore btn-bg5">
            {{ $t('store.AddOtherStore') }}
          </p>
        </el-tooltip>
        <el-button
        :loading="addTTLoading"
        type="success"
        v-if="paymentList.IsAdmin == true && showAddTikTokBtn == true"
        class="add-tt-btn"
        @click="ttAdded"
        circle
        >{{ $t('store.addTikTokStore') }}
      </el-button>
        <el-button @click="InventorylistBtn" class="Inventorylist" v-if="InventoryListBtn">{{
          $t('Inventorylist.Inventorylist')
        }}</el-button>
      </div>
      <div class="activeName">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('store.settings')" class="first" name="first">
            <template v-if="StoreList && StoreList.length > 0">
              <el-table :data="StoreList" style="width: 100%">
                <el-table-column prop="StoreName" align="center" :label="$t('store.name')">
                </el-table-column>
                <el-table-column align="center" :label="$t('store.status')">
                  <div slot-scope="scope" class="content">
                      <span v-if="scope.row.StoreType !== 'Offline'">
                        <span v-if="scope.row.Status === 1" style="color: #6788FF;"
                              class="bakingpackaging">{{ $t("store.Authorized") }}</span>
                        <span v-if="scope.row.Status === -1" style="color: #e6a23c;"
                              class="bakingpackaging">{{ $t("store.Unauthorized") }}</span>
                        <span v-if="scope.row.Status === -2" style="color: #ff6767;"
                              class="bakingpackaging">{{ $t("store.Unauthorized") }}</span>
                        <span v-if="scope.row.Status === -3"
                              class="bakingpackaging">{{ $t("store.Unauthorized") }}</span>
                      </span>
                    <span v-else>
                        <span style="color: #6788FF;">{{ $t("store.Offline") }}</span>
                      </span>
                  </div>
                </el-table-column>
                <el-table-column
                  prop="CreateDate"
                  align="center"
                  :label="$t('store.authorization')"
                >
                </el-table-column>
                <el-table-column align="center" :label="$t('store.actions')">
                  <div slot-scope="scope">
                    <div
                      v-if="scope.row.StoreType !== 'Offline'"
                      class="status comBtn col-col5 border-col5"
                      @click="actions(scope.row.ApiColumn1)"
                    >
                      {{ $t("store.reauthorize") }}
                    </div>
                  </div>
                </el-table-column>
              </el-table>
            </template>
            <el-empty description="No data" v-else></el-empty>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 弹出框 -->
      <!-- <el-dialog :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <div class="Add-Store">{{ $t("store.orders") }}</div>
          <el-form-item :label-width="formLabelWidth" class="Store">
            <span class="Name">{{ $t("store.store") }}</span>
            <el-input
              v-model.trim="form.name"
              :placeholder="$t('store.please')"
              autocomplete="off"
            ></el-input>
            <span class="myshopify">{{ $t("store.myshopify") }}</span>
          </el-form-item>
        </el-form>
        <div class="NoShopifyStoreTitle">
          <p>{{ $t("store.NotShopify") }}</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <div class="footerbox">
            <button class="Cancel btn_com comBtn col-col0 border-col0" @click="Cancel">{{ $t("store.cancel") }}</button>
            <button class="Save btn_com comBtn btn-bg0 border-col0" type="primary" @click="confirmStore">{{
                $t("store.save")
              }}
            </button>
          </div>
        </div>
      </el-dialog> -->
      <!-- 添加线下店铺 -->
      <el-dialog :visible.sync="dialogFormOffline">
        <el-form :model="form">
          <div class="Add-Store">{{ $t("store.AddOther") }}</div>
          <el-form-item :label-width="formLabelWidth" class="Store">
            <span class="Name">{{ $t("store.store") }}</span>
            <el-input
              v-model.trim="form.name"
              :placeholder="$t('store.pleaseLink')"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="NoShopifyStoreTitle">
          <p>{{ $t("store.AddOtherStoreLife") }}</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <div class="footerbox">
            <button class="Cancel btn_com comBtn col-col0 border-col0" @click="Cancel">{{ $t("store.cancel") }}</button>
            <button class="Save btn_com comBtn btn-bg0 border-col0" type="primary" @click="confirmOfflineStore(form.name)">
              {{ $t("store.save") }}
            </button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {searchUrlParams} from '@/utils/tools';

export default {
  data: () => {
    return {
      InventoryListBtn:false,
      dispatch: null,
      page: "Store",
      activeName: "first",
      banner: "",
      itemname: "",
      StoreList: [], // 会员店铺列表
      value: "",
      dialogFormVisible: false, //弹框控制
      StoreWelcome: false,
      currentPage: 1,
      form: {
        //店铺名字
        name: ""
      },
      formLabelWidth: "120px",
      dialogFormOffline: false,
      DisplayShowWords: false,
      paymentList: []
    };
  },
  components: {
  },
  computed: {
    ...mapState(["theme", "userInfo"])
  },
  created() {
    this.dispatch = this.$store.dispatch;
    const params = this.$route.params || {};
    const search = searchUrlParams(decodeURIComponent(location.search)) || {};
    const {type} = params;
    const authInfo = search;
    if (type !== "addstore" && type !== "reauthorize") return;
    authInfo && this.getAuthInfo(params, authInfo);
  },
  mounted() {
    this.banner = this.$t("store.bannername");
    this.itemname = this.$t("store.itemname");
    this.getData();
    this.menterData();
    this.InventoryGetList();
    gtag('event', 'conversion', {'send_to': 'AW-10836134056/u_YXCLrI7P8YEKiRia8o'});
  },
  methods: {
    menterData() {
      this.dispatch('member/GetMemberVoMe')
      .then((res) => {
        this.paymentList = res.Result;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    // 获取备货库存列表
    InventoryGetList() {
      let that = this;
      that.dispatch('store/InventoryGetListSt',{
          CurrentPageIndex: 1,
          PageSize: 10,
        })
        .then((res) => {
          if (res?.Items?.length>0) {
             this.InventoryListBtn = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    InventorylistBtn() {
      window.location.href = `${window.location.origin}/Inventorylist`
    },
    pageChange(e) {
      console.log(e);
    },
    Added() {
      window.location.href = 'https://apps.shopify.com/usadrop'
      /* this.dialogFormVisible = true; */
    },
    // 获取会员店铺列表
    getData() {
      let that = this;
      that.dispatch('store/GetListSt')
        .then(res => {
          that.StoreList = res.Result;
        })
        .catch(err => {
          console.log(err);
        });
      /* this.ExistStore(); */
    },
    // 获取绑定店铺授权链接
    actions(name, isAdd) {
      let that = this;
      that.dispatch('store/GetOauthRedirectUrlSt', {
        storeName: name
      })
        .then(res => {
          if (res.Success == true) {
            let oauthUrl = res.Result;
            let locationUrl = window.location.origin;
            let destinationUrl = "";
            if (isAdd) {
              destinationUrl = locationUrl + "/Store/addstore";
              // destinationUrl = locationUrl + "/loading";
            } else {
              destinationUrl = locationUrl + "/Store/reauthorize";
            }
            destinationUrl = escape(destinationUrl);
            oauthUrl = escape(oauthUrl);
            // console.log(oauthUrl);
            let shopifyUrl = `https://webapi.usadrop.com/ShopifyOAuth/OAuth.html?shop=${name}&destinationUrl=${destinationUrl}&oauthUrl=${oauthUrl}`;
            // console.log(shopifyUrl);
            window.location.href = shopifyUrl;
          } else {
            that.$message.error(that.$t("tips.storeLinkError"));
          }
          /* that.ExistStore(); */
        })
        .catch(err => {
          console.log(err);
        });

    },
    // 弹出框 取消按钮
    Cancel() {
      this.form.name = "";
      this.dialogFormVisible = false;
      this.dialogFormOffline = false;
    },
    // 点击添加店铺进入授权
    confirmStore() {
      let that = this;
      if (that.form.name == "") {
        that.$message.error(that.$t("tips.storeNameEmpty"));
        return;
      }
      that.ExistStore();
      /* that.actions(that.form.name, "add"); */
    },
    getAuthInfo(params, authInfo) {
      // 授权信息接口解密
      let that = this;
      that.dispatch('store/ReceiveShopifyTokenSt', {
        code: authInfo.code,
        hmac: authInfo.hmac,
        shop: authInfo.shop,
        timestamp: authInfo.timestamp
      })
        .then(res => {
          authInfo.shop = authInfo.shop.replace(".myshopify.com", "");
          if (res.Success == true) {
            if (params.type == "addstore") {
              that.AddStore(authInfo.shop, res.ResData, 'Shopify');
            } else if (params.type == "reauthorize") {
              that.refreshStore(authInfo.shop, res.ResData);
            }
          } else {
            that.$message.error(that.$t("tips.storeAuthFail"));
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 更新店铺
    refreshStore(name, token) {
      const that = this;
      that.dispatch('store/RefreshStoreSt', {
        StoreName: name,
        Token: token
      })
        .then(res => {
          if (res?.Success) {
            that.$message.success(that.$t("tips.storeReauthorizeStore"));
            that.getData(); //获取更新后店铺列表
            window.location.href = "/NewStore";
          } else {
            that.$message.error(that.$t("tips.storeAddFail"));
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    AddOfflineStore() {
      this.dialogFormOffline = true;
    },
    // 添加店铺
    confirmOfflineStore(name) {
      //console.log(name)
      let that = this;
      that.dispatch('store/AddStoreSt', {
        StoreName: name,
        StoreType: "Offline",
      })
        .then(res => {
          if (res.Success) {
            that.getData();
            that.$message.success(that.$t("tips.storeAddStore"));
            setTimeout(() => {
              window.location.href = '/NewStore';
            }, 50);
          } else {
            that.$message.error(that.$t("tips.storeAddFail"));
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 添加店铺
    AddStore(name, token, storeType) {
      let that = this;
      that.dispatch('store/AddStoreSt', {
        StoreName: name,
        MemberCode: "",
        StoreType: storeType,
        Key: "",
        Token: token
      })
        .then(res => {
          if (res?.Success) {
            that.getData();
            that.$message.success(that.$t("tips.storeAddStore"));
            setTimeout(() => {
              window.location.href = '/NewStore';
            }, 666);
          } else {
            that.$message.error(that.$t("tips.storeAddFail"));
          }
        })
        .catch(err => {
          console.log(err);
        });
      /*
          点击Connect或者ReConnect时，统一经由：
          https://webapi.hzpdex.com/ShopifyOAuth/OAuth.html?shop={shop}&destinationUrl={destinationUrl}&oauthUrl={oauthUrl}
          接收参数：
          1)、shop
              店铺的二级域名（填写的字符串）
          2)、oauthUrl
              去shopify授权的网址（来自于接口：/api/Store/GetOauthRedirectUrl）
          3)、destinationUrl
              Shopify同意授权后，回到的前端页面
          补充：授权最终跳转回destinationUrl后，URL上会自动带上code,hmac,shop,state几个参数。
          1)、在该页面上调用/api/Store/ReceiveShopifyToken
          2)、再调用 /api/Store/AddStore （触发于添加按钮）  或者  /api/Store/RefreshStore （触发于Reconnect）
          */
    },
    // 判断店铺是否存在
    ExistStore() {
      this.dispatch('guide/ExistStoreGu', {
        StoreKey: this.form.name
      })
        .then(res => {
          if (res?.Success) {
            this.$message.error(this.$t('store.TheStoreBefore'))
            //this.getData(); //获取更新后店铺列表
          } else {
            this.actions(this.form.name, "add");
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    /* 返回会员列表页面 */
    Upgrade() {
      this.$router.push("/Upgrade");
    }
  }
};
</script>

<style scoped lang="scss">
.store {
  width: 1400px;
}
$col-col5: #3F6AFF;
::v-deep .el-tabs {
  border-radius: 10px;
}

/* 查询订单对话框 */
.DialogBoxOrder {
  width: 295px;
  height: 60px;
  background: #aeaeae;
  z-index: 21474899 !important;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: -50px;
  right: 0

}

.DialogBoxOrder p {
  /*color: #0000EE;*/
  color: #fff;
  margin-left: 15px;
  margin-top: 5px;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  line-height: 25px;
}

.DialogBoxOrder::before {
  content: "";
  position: absolute;
  bottom: -15%;
  right: 40px;
  width: 22px;
  height: 22px;
  margin-top: -10px;
  background: inherit; /*自动继承父元素的背景*/
  transform: rotate(45deg);
}

.NoShopifyStoreTitle {
  text-align: center;
}

.NoShopifyStoreTitle p {
  font-style: italic;
  color: rgb(76, 139, 245);
}
.particulars {
  width: 100%;
  background: #eff1f3;
  min-width: 1320px;
  border-radius: 10px;
}

.activeName {
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  padding-bottom: 32px;
  margin-top: 20px;
}
.btn_box {
  width: 100%;
  background: #fff;
  min-width: 1320px;
  border-radius: 10px;
  line-height: 70px;
  font-family: 'Regular';
  .add-tt-btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    background-color: #000000;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #000000;
    margin-left: 15px;
    &:hover {
      background: #4d4d4d;
      color: #fff;
    }
  }
  .orders {
    display: inline-block;
    width: 180px;
    height: 40px;
    background-color: #93df89;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    margin-left: 50px;
    border: 1px solid #93df89;
    &:hover {
      background: #bff4b8;
      color: #fff;
    }
  }
  .Inventorylist {
    width: 180px;
    height: 40px;
    background: #3f6aff;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #3f6aff;
    margin-left: 15px;
    &:hover {
      background: #6c8dff;
      color: #fff;
    }
  }
  .tikTokBtn {
    display: inline-block;
    width: 180px;
    height: 40px;
    background: #000;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #000;
    &:hover {
      background: #000;
      opacity: 0.8;
      color: #fff;
    }
  }
  .AddOfflineStore {
    width: 180px;
    margin-left: 15px;
    &:hover {
      background: #6c8dff !important;
      color: #fff;
    }
  }
  .linkStore {
    width: 230px;
    margin-left: 15px;
    &:hover {
      background: #bff4b8;
      color: #fff;
    }
  }
  .Upgrade {
    display: inline-block;
    width: 140px;
    height: 40px;
    background-color: #ff6638;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #fe9272;
  }
  .Upgrade:hover {
    background: #fe9272;
    color: #fff;
  }
}
.breadCrumb {
  padding-top: 60px;
}
::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 20px 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-tabs__nav {
  margin-left: 32px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: $col-col5;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.activeName .first .paging {
  padding-top: 186px;
  padding-right: 35px;
  padding-left: 32px;
}
.activeName .first .Get1 {
  display: inline-block;
  position: absolute;
  bottom: 36px;
  right: 110px;
  width: 35px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #000000;
  opacity: 0.3;
  border-radius: 5px;
  line-height: 28px;
  text-align: center;
  margin-right: 21px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页内容区的总长度 */
::v-deep .activeName .el-tabs__content {
  background-color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.activeName .first .Get2 {
  position: absolute;
  bottom: 38px;
  right: 60px;
  font-size: 14px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  color: #000000;
  opacity: 0.6;
}

.pagebox {
  width: 100%;
  height: 88px;
  padding-bottom: 4px;
}

.pagebox .el-pagination {
  float: left;
  margin-top: 26px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.page_sign {
  height: 28px;
  line-height: 28px;
  color: #585858;
  font-size: 16px;
  float: right;
  margin-top: 28px;
  padding-right: 35px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.page_sign span {
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-table th,
.el-table tr {
  height: 71px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-table__row {
  height: 86px !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-table__expanded-cell[class*="cell"] {
  width: 1320px !important;
  padding-left: 294px !important;
  padding-top: 24px !important;
  padding-bottom: 12px !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.activeName .particulars1 {
  height: 92px;
  margin-bottom: 24px;
  width: 800px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-tabs__header {
  margin: 0 !important;
  background-color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.status {
  font-size: 14px;
  height: 40px;
}
::v-deep .status.el-button--primary.el-button:hover {
  background: #e9efff;
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;

}

/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background: #6788ff;
}

.el-table::before {
  background: #dbdbe4;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-table thead {
  //color: rgb(0, 0, 0, 0.8);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-table {
  //color: rgb(0, 0, 0, 0.8);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 新增弹框 */

::v-deep .el-dialog__wrapper {
  height: 966px;
}

::v-deep .el-dialog {
  margin-top: 25vh !important;
  width: 810px !important;
  height: 282px !important;
  background: #ffffff !important;
  box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.14) !important;
  border-radius: 21px !important;
  overflow: hidden;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Add-Store {
  background-color: #ffffff;
  width: 810px;
  height: 55px;
  line-height: 55px;
  color: #333333;
  padding-left: 42px;
  font-size: 16px;
  border-bottom: 1px solid #e4e6ef;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-dialog__header {
  padding: 0 !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-dialog__headerbtn {
  position: absolute !important;
  top: 20px !important;
  right: 32px !important;
  padding: 0 !important;
  background: 0 0 !important;
  border: none !important;
  outline: 0 !important;
  cursor: pointer !important;
  font-size: 16px !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #fff !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Store {
  margin-top: 32px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Store .Name {
  display: inline-block;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000000;
  line-height: 19px;
  opacity: 0.8;
}

::v-deep .Store .el-form-item__content {
  margin-left: 118px !important;
  height: 48px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .Store .el-input {
  display: inline-block;
  font-size: 14px;
  width: 320px;
  height: 48px;
  background: #ffffff;
  margin-left: 32px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .Store .el-input__inner {
  width: 320px;
  height: 40px;
  border-radius: 24px;
  padding-left: 52px;
  font-size: 14px;
  overflow: hidden;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-input__inner:focus {
  border-color: #c0c4cc;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.Store .myshopify {
  width: 144px;
  height: 19px;
  font-size: 16px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000000;
  line-height: 19px;
  opacity: 0.8;
  margin-left: 32px;
}
.btn_com {
  width: 200px;
  height: 45px;
  border-width: 2px;
  border-style: solid;
  font-weight: 600;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Cancel {
  position: absolute;
  top: 202px;
  left: 176px;
}
.Save {
  position: absolute;
  top: 202px;
  left: 474px;
  border-width: 2px;
  border-style: solid;
}
::v-deep .el-tabs__nav-wrap::after {
  background: #eff1f3 !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
</style>
