<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <!-- 详情页面 -->
    <template v-if="BindStore">
      <!-- 内容 -->
      <div class="activeName_big_box_BindStore">
        <div class="activeName">
          <div class="check_type col-col0">
            <div class="Aliexpress_Product">
              <a href="/AliexpressProducts">
                <span>{{ $t("URL.AliexpressLink") }}</span>
                <span>》</span>
              </a>
            </div>
            <div class="Import_List">
              <a href="/ImportList">
                <span>{{ $t("URL.Import") }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("URL.List") }}</span>
                <span>》</span>
              </a>
            </div>
            <div class="My_Product">
              <a href="/MyProduct">
                <span>{{ $t("URL.PublishedProducts") }}</span>
                <span>》</span>
              </a>
            </div>
          </div>
          <div class="activeNameTwoBox">
            <div class="Sourcing">{{ $t("URL.aliexpress") }}</div>
            <div class="ProductLink">
              <input
                type="text"
                v-model="ProductLink"
                @input="handlerPageNo"
                :placeholder="$t('URL.product')"
              />
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button class="Save" type="primary" @click="Save">{{
                $t("URL.Confirm")
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="activeName_big_box"> <!-- 内容 -->
        <div class="activeName">
          <div class="check_type col-col0">
            <div class="Aliexpress_Product">
              <a href="/AliexpressProducts">
                <span>{{ $t("URL.AliexpressLink") }}</span>
                <span>》</span>
              </a>
            </div>
            <div class="Import_List">
              <a href="/ImportList">
                <span>{{ $t("URL.Import") }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("URL.List") }}</span>
                <span>》</span>
              </a>
            </div>
            <div class="My_Product">
              <a href="/MyProduct">
                <span>{{ $t("URL.PublishedProducts") }}</span>
                <span>》</span>
              </a>
            </div>
          </div>
          <div class="activeNameTwoBox">
            <div class="Sourcing">{{ $t("URL.aliexpress") }}</div>
            <div class="ProductLink">
              <input
                type="text"
                v-model="ProductLink"
                @input="handlerPageNo"
                :placeholder="$t('URL.product')"/>
            </div>
            <div slot="footer" class="dialog-footer" v-if="paymentList.IsAdmin == true">
              <button class="Save comBtn btn-bg0" @click="Save">{{
                $t("URL.Confirm")
              }}</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  watch: {},
  data: () => {
    return {
      dispatch: null,
      page: "URL",
      activeName: "first",
      ProductLink: "", //搜索链接
      BindStore: false, //是否绑定店铺
      BindStoreList: [], // 是否绑定店铺
      paymentList: []
    };
  },
  components: {
  },
  created(){
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.CheckGuide(); // 是否绑定店铺
    this.menterData();
    const _self = this;
    document.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key === 13) {
        _self.Save();
      }
    };
  },
  computed: mapState(["userInfo", "theme"]),
  methods: {
    menterData() {
      this.dispatch('member/GetMemberVoMe')
      .then((res) => {
        this.paymentList = res.Result;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    CheckGuide() { /* 是否绑定店铺 */
      this.dispatch('guide/checkGuideGu')
        .then((res) => {
          this.BindStoreList = res.Result;
          if (res.Result.IsStoreComplete == true) {
            this.BindStoreList = res.Result;
            this.BindStore = false;
          } else {
            this.BindStore = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
   /*  AliexpressProduct() {
      this.$router.push("/AliexpressProducts");
    }, */
    ImportList() {
      this.$router.push("/ImportList");
    },
    MyProduct() {
      this.$router.push("/MyProduct");
    },
    handlerPageNo() {
      this.ProductLink = this.ProductLink.split("?")[0];
    },
    dialogFormVisible() {
      this.ProductLink = "";
    },
    Save() { // 添加按钮
      if (this.ProductLink == "") {
        return this.$message.warning(this.$t("URL.empty"));
      } else {
        this.ProductLink = this.ProductLink.split("?")[0];
      }
      let loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
      });
      this.dispatch('souring/CrawlProductPostSr', {
          url: this.ProductLink,
          type: 1,
        })
        .then((res) => {
          loading.close();
          if (res.Success) {
            this.ProductLink = "";
            this.$router.push("ImportList");
            this.$message.success(this.$t("URL.successfully"));
          } else {
            this.$message.error(res.ErrMsg);
          }
        })
        .catch((err) => {
          this.$message.error(this.$t("URL.failed"));
          console.log(err);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 25px !important;
  font-family: "Regular";
}
.activeName {
  padding-bottom: 10px;
  width: 1320px;
  position: relative;
  overflow: hidden;
  font-family: "Regular";
}
.activeNameTwoBox {
  width: 1320px;
  height: 310px;
  background: #fff;
  border-radius: 20px;
}
.check_type {
  width: 100%;
  height: 60px;
  display: flex;
  font-size: 14px;
  padding-left: 10px;
}
.Aliexpress_Product {
  width: 200px;
  height: 40px;
  background: #15104B;
  border-radius: 50px;
  cursor: pointer;
  a {
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
    color: #ffffff;
  }
  &:hover {
    transform: scale(1.1);
  }
  span:nth-child(1) {
    line-height: 40px;
    margin-left: 40px;
  }
  span:nth-child(2) {
    margin-left: 20px;
    line-height: 40px;
  }
}
.Import_List {
  width: 200px;
  height: 40px;
  background: #fff;
  margin-left: 35px;
  border-radius: 50px;
  cursor: pointer;
  a {
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
    color: #000;
  }
  &:hover {
    transform: scale(1.1);
  }
  span:nth-child(1) {
    line-height: 40px;
    margin-left: 50px;
    font-size: 14px;
  }
  span:nth-child(2) {
    margin-left: 28px;
  }
}
.My_Product {
  width: 200px;
  height: 40px;
  background: #fff;
  margin-left: 35px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
    color: #000;
  }
  &:hover {
    transform: scale(1.1);
  }
  span:nth-child(1) {
    line-height: 40px;
    margin-left: 15px;
    font-size: 14px;
  }
  span:nth-child(2) {
    margin-left: 5px;
  }
}
.Sourcing {
  width: 452px;
  height: 52px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  opacity: 0.8;
  margin-left: 24px;
  padding-top: 28px;
}
.ProductLink input {
  width: 1272px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #878787;
  border-radius: 24px;
  margin-top: 55px;
  margin-left: 24px;
  padding-left: 34px;
  font-size: 14px;
}
.dialog-footer {
  margin-top: 32px;
  margin-left: 401px;
}
.Cancel {
  width: 214px;
  height: 48px;
  border: 2px solid #636ae7 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #4c8bf5 !important;
  background-color: #fff;
}
.Save {
  margin-left: 100px !important;
  width: 180px;
  height: 48px;
  border-radius: 50px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background: #15104B !important;
  margin-top: 20px;
  border: 0;
}
.Save:hover {
  background: #3C3772 !important;
  color: #fff;
}
</style>
