<template>
  <div>
    <lastedNews></lastedNews>
    <div class="scroll" :class="{ show: isActive }">
      <div class="particulars" :class="[theme === 'common' ? '' : 'dark']">
        <div class="main_big_box">
          <div class="main">
            <!-- 搜索框 -->
            <div class="searchbox">
              <div class="search_input">
                <div class="search_icon el-icon-search"></div>
                <input type="text" :placeholder="$t('orders.placeholder')" v-model="keywords" />
              </div>
              <div class="topbox">
                <div class="comBtn btn-bg0" @click="getOrderList()">
                  {{ $t('orders.search') }}
                </div>
                <div class="comBtn btn-bg0" @click="toggleMore">
                  {{ $t('orders.filter') }}
                </div>
                <div class="comBtn btn-bg0" v-if="paymentList.IsAdmin == true" @click="UploadOrder">
                  {{ $t('orders.UploadOrder') }}
                </div>
                <div class="comBtn btn-bg0" v-if="paymentList.IsAdmin == true" @click="AddOrder">
                  {{ $t('orders.AddOrder') }}
                </div>
                <div class="Synchronize comBtn btn-bg7" v-if="paymentList.IsAdmin == true">
                  <a href="/synchronize">
                    <span @click="getOrders">{{ $t('orders.Synchronize') }}</span>
                  </a>
                </div>
                <div class="add_shopify comBtn btn-bg2">
                  <a href="https://shopify.pxf.io/rQvo6d" target="_blank">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="If you don't have a Shopify store, click this button to create one quickly."
                      placement="top-start">
                      <p class="comBtn linkStore btn-bg2">
                        Add Shopify
                      </p>
                    </el-tooltip>
                  </a>
                </div>
              </div>
            </div>
            <!-- 高级搜索弹框 -->
            <el-dialog :visible.sync="isMore" width="600px" custom-class="mydialog" :center="true">
              <el-form>
                <el-form-item :label="$t('orders.dialog_country')" label-width="120px">
                  <el-select
                    v-model="selectCountry"
                    filterable
                    clearable
                    :placeholder="$t('')"
                    class="myselect"
                  >
                    <div>
                      <el-option
                        v-for="item in countrys"
                        :key="item.index"
                        :label="item.EnglishName"
                        :value="item.DoubleCharacterCode"
                      >
                      </el-option>
                    </div>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('orders.dialog_store')" label-width="120px">
                  <el-select v-model="selectStore" clearable :placeholder="$t('')" class="myselect">
                    <el-option
                      v-for="item in stores"
                      :key="item.index"
                      :label="item.StoreName"
                      :value="item.StoreId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('orders.dialog_time')" label-width="120px">
                  <el-date-picker
                    class="myselect"
                    v-model="selectTime"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :range-separator="$t('orders.to')"
                    :start-placeholder="$t('')"
                    :end-placeholder="$t('')"
                    @change="timeChange"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('orders.ProductName')" label-width="120px">
                  <div class="Contact_input">
                    <el-input
                      v-model="productName"
                      :placeholder="$t('orders.FilterProductName')"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item :label="$t('orders.Contact')" label-width="120px">
                  <div class="Contact_input">
                    <el-input
                      v-model="contact"
                      :placeholder="$t('orders.FilterContact')"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item :label="$t('orders.SalesRecord')" label-width="120px">
                  <div class="Contact_input">
                    <el-input
                      v-model="salesRecord"
                      :placeholder="$t('orders.FilterSalesRecord')"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item :label="$t('orders.statusFilter')" label-width="120px">
                  <el-select v-model="PlatformStatus" clearable class="myselect">
                    <el-option
                      v-for="item in optionsType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <!--  <el-form-item :label="$t('orders.statusFilter')" label-width="120px">
                  <div class="Contact_input">
                    <el-input
                      v-model="CustomerPayment"
                      :placeholder="$t('orders.FilterSalesRecord')"
                    ></el-input>
                  </div>
                </el-form-item> -->
              </el-form>
              <div slot="footer" class="dialog-footer">
                <button @click="moreSearch" class="comBtn btn-bg0 min-wid200">
                  {{ $t('orders.Confirm') }}
                </button>
              </div>
            </el-dialog>
            <!-- 整体表格 -->
            <div class="list">
              <div class="typebox">
                <div class="typebox-tabs">
                  <div
                    class="type"
                    v-for="(item, index) in types"
                    :key="index"
                    :class="orderType == index + 1 ? 'select' : ''"
                    @click="selectType(index + 1)"
                  >
                    {{ `${$t(item)}` }}<span></span>
                  </div>
                </div>
                <div
                  v-if="BindStoreList.IsStoreComplete && BindStoreList.IsOfflineStoreComplete"
                  class="typebox-btn-group"
                >
                  <!-- v-show="CenterMessage.AutoPaymentMode == false"  是否开启自动付款 -->
                  <div
                    class="all comBtn border-col5 col-col5"
                    @click="AddStoreBind"
                    v-if="paymentList.IsAdmin == true"
                  >
                    {{ $t('orders.AddStore') }}
                  </div>
                  <div
                    class="all comBtn border-col5 col-col5"
                    @click="ExportExcelBtn"
                    v-if="paymentList.IsAdmin == true"
                  >
                    {{ $t('orders.ImportOrder') }}
                  </div>
                  <div
                    class="all comBtn border-col5 col-col5"
                    @click.stop="payAllUpgrade"
                    v-if="paymentList.IsAdmin == true"
                  >
                    {{ $t('orders.fulfill') }}
                  </div>
                </div>
                <div v-else class="typebox-btn-group">
                  <div
                    class="all comBtn border-col5 col-col5"
                    @click="AddStoreBind"
                    v-if="paymentList.IsAdmin == true"
                  >
                    {{ $t('orders.AddStore') }}
                  </div>
                  <div
                    class="all comBtn border-col5 col-col5"
                    @click="ExportExcelBtn"
                    v-if="paymentList.IsAdmin == true"
                  >
                    {{ $t('orders.ImportOrder') }}
                  </div>
                  <div
                    class="all comBtn border-col5 col-col5"
                    @click.stop="payAllUpgrade"
                    v-if="paymentList.IsAdmin == true"
                  >
                    {{ $t('orders.fulfill') }}
                  </div>
                </div>
              </div>

              <div class="speed-progress" v-if="Fulfilled">
                <div class="progress-fulfilled">
                  <span>{{ $t('orders.OrdersFulfilled') }}</span>
                  <span>{{ MembershipInterests.UsedTimes }}</span>
                  <span>/</span>
                  <span>{{ MembershipInterests.TotalTimes }}</span>
                </div>
                <el-progress
                  :percentage="pmgressbar"
                  style="width: 170px; margin-top: 8px"
                  :show-text="false"
                ></el-progress>
                <div class="MonthlyRed" @click="MonthlyRed()">
                  <span v-if="paymentList.GradeId === 1">
                    <img
                      src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591998939_MemberJICHUSlice.png"
                      alt=""
                    />
                  </span>
                  <span v-if="paymentList.GradeId === 2">
                    <img
                      src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591999005_MemberVIPSlice16.png"
                      alt=""
                    />
                  </span>
                  <span v-if="paymentList.GradeId === 3">
                    <img
                      src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591998972_MemberSVIPSlice16.png"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <ul class="main-list">
              <li class="title">
                <div class="content">
                  <div class="title_li">{{ $t('orders.id') }}</div>
                  <div class="title_li">{{ $t('orders.time') }}</div>
                  <div class="title_li">
                    {{ $t('orders.ProductInformations') }}
                  </div>
                  <div class="title_li">{{ $t('orders.user') }}</div>
                  <div class="title_li">{{ $t('orders.payment') }}</div>
                  <div class="title_li">{{ $t('orders.Tax') }}</div>
                  <div class="title_li">{{ $t('orders.pay') }}</div>
                  <div class="title_li">{{ $t('orders.Tracking') }}</div>
                  <div class="title_li">{{ $t('orders.status') }}</div>
                </div>
              </li>
              <!--  @click="getOrderDetail(index)" -->
              <template v-for="(order, index) in orderlist">
                <li
                  class="common"
                  :class="getOrder === index ? 'canceled' : ''"
                  :key="order.SalesRecord"
                >
                  <!-- 表格信息 -->
                  <div class="contentMessage">
                    <div class="content_li">{{ order.SalesRecord }}</div>
                    <div class="content_li">{{ order.OrderTime }}</div>
                    <div class="content_li">
                      <template v-if="order.OrderDetailVos">
                        <template v-for="(productList, itemIndex) in order.OrderDetailVos">
                          <div
                            class="productListContent"
                            :class="[productList.IsExclude ? 'cancel' : '']"
                            :key="productList.id"
                          >
                            <div class="productListContentBox">
                              <div class="productListContentImg">
                                <img
                                  :src="productList.ProductPictureUrl"
                                  alt=""
                                  style="width: 60px; height: 60px"
                                />
                              </div>
                              <div>
                                <div
                                  class="productListProductName"
                                  @mouseenter="enterProduct(productList)"
                                  @mouseleave="leaveProduct(productList)"
                                >
                                  {{ productList.ProductName }}
                                  {{ productList.Spec }}
                                </div>
                                <div
                                  class="ShowProductListProductName"
                                  v-show="productList.ShowProductName"
                                >
                                  {{ productList.ProductName }}
                                  {{ productList.Spec }}
                                </div>
                                <div class="productListProductNumber">
                                  <div class="cheapPrice">
                                    {{ productList.Num }} * ${{ productList.QuotedPrice }}
                                  </div>
                                  <!-- <div
                                    v-if="
                                      paymentList.GradeId > 1 && productList.MallDiscountAmount > 0
                                    "
                                    style="color: #d70000; text-decoration: line-through"
                                  > -->
                                  <!-- {{ productList.Num }} * ${{ productList.OriginalQuotedPrice }} -->
                                  <!-- </div> -->
                                </div>
                                <span class="podTag" v-if="productList.PodAttr">pod</span>
                                <div v-if="productList.ProductSaleType == 2" class="NoNeedToShip">
                                  <p>{{ $t('orders.NoNeedToShip') }}</p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div v-if="productList.IsNotProvide" style="color: #bc0c34">
                                <p>{{ $t('orders.failedFulfillment') }}</p>
                              </div>
                              <div v-if="productList.IsNotProvide" style="color: BC0C34"></div>
                            </div>
                            <div v-if="order.OrderStatus !== 'Paid'" style="text-align: center">
                              <span v-if="paymentList.IsAdmin == true">
                                <span
                                  @click.stop="cancelItem(index, itemIndex)"
                                  v-if="productList.IsExclude"
                                  style="
                                    border: 0;
                                    background: none;
                                    color: #bc0c34;
                                    margin-right: 20px;
                                  "
                                  >{{ $t('orders.RecoverItem') }}</span
                                >
                                <span class="cancel-btn" v-else>
                                  <el-button
                                    slot="reference"
                                    @click="cancelItem(index, itemIndex)"
                                    style="border: 0; background: none; color: #bc0c34"
                                    >{{ $t('orders.CancelItem') }}</el-button
                                  >
                                </span>
                              </span>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                    <div class="content_li" @click.stop="showUser(index)">
                      <i>{{ order.Contact }}</i>
                      <span :class="[order.isInfo ? 'info_down' : '']"></span>
                    </div>
                    <div class="content_li">
                      {{ order.CustomerPayment }}
                    </div>
                    <div class="content_li">${{ order.SalesTaxAmount.toFixed(2) }}</div>
                    <div class="content_li">
                      <div v-if="order.OrderStatus === 'Unpaid' && order.IsQuotedPrice">
                        <div v-if="order.DiscountAmount !== 0" class="copper_coin">
                          ${{ order.QuotedPrice }}
                        </div>
                        <div>
                          ${{ Number(order.QuotedPrice - order.DiscountAmount).toFixed(2) }}
                          <!-- {{ order.QuotedPrice }}  ${{ order.DiscountAmount }} -->
                        </div>
                      </div>
                      <div v-else>${{ order.QuotedPrice }}</div>
                    </div>
                    <div class="content_li">
                      <div class="OrderTrackingNumber">
                        <!-- 33XJP788442301000931507 -->
                        <p>{{ order.TrackingNumber || '&nbsp;&nbsp;&nbsp;&nbsp;一' }}</p>
                      </div>
                    </div>
                    <!--  <div
                      class="ShowOrderTrackingNumber"
                      v-if="ShowTrackingNumber"
                    >
                      {{ order.TrackingNumber }}
                    </div> -->
                    <div class="content_li">
                      <div class="order_Btn" v-if="paymentList.IsAdmin == true">
                        <!-- v-show="CenterMessage.AutoPaymentMode == false" -->
                        <div v-if="order.OrderStatus === 'Unpaid' && order.IsQuotedPrice">
                          <button
                            @click.stop="goToUpgrade(index)"
                            v-show="!CenterMessage.AutoPaymentMode"
                            class="comBtn btn-bg7 min-wid100"
                          >
                            {{ $t('orders.pay1') }}
                          </button>
                        </div>
                        <button
                          v-else-if="!order.IsQuotedPrice && order.OrderStatus === 'Unpaid'"
                          disabled="disabled"
                          class="plain"
                        >
                          {{ $t('orders.Quoting') }}
                        </button>
                        <button
                          class="comBtn btn-bg0 min-wid100"
                          v-else-if="order.OrderStatus === 'Paid'"
                          disabled="disabled"
                        >
                          {{ $t('orders.Paid') }}
                        </button>
                        <button
                          v-else-if="order.OrderStatus === 'Cancel'"
                          disabled="disabled"
                          class="CanceledBtn"
                        >
                          {{ $t('orders.Canceled') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <!-- 订单用户信息form -->
                <div class="infobox" @click.stop="stopClick" v-show="order.isInfo">
                  <el-form :ref="`form${index}`" :model="order.user" :rules="rules">
                    <div class="Form_big_box">
                      <div class="LeftForm">
                        <!-- Contact Name -->
                        <el-form-item prop="Contact">
                          <div class="contact_name_input">
                            <div class="contact_name_words">
                              <p>{{ $t('orders.contact_name') }}</p>
                            </div>
                            <div class="REQUIRED">*</div>
                            <div class="order_user_Contact">
                              <el-input v-model="order.user.Contact"></el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <!-- Country -->
                        <el-form-item prop="CountryNameEn">
                          <div class="contact_name_input">
                            <div class="">{{ $t('orders.Country') }}</div>
                            <div class="PleaseSelect_Country">
                              <el-select
                                class=""
                                v-model="order.user.CountryCode"
                                disabled
                                :placeholder="$t('orders.PleaseSelect')"
                              >
                                <el-option
                                  v-for="item in countrys"
                                  :key="item.index"
                                  :label="item.EnglishName"
                                  :value="item.DoubleCharacterCode"
                                >
                                </el-option>
                              </el-select>
                            </div>
                          </div>
                        </el-form-item>
                        <!-- States -->
                        <el-form-item prop="Province">
                          <div class="contact_Province_input">
                            <div class="contact_Province_words">{{ $t('orders.Province') }}</div>
                            <div class="REQUIRED">*</div>
                            <div class="order_user_Province">
                              <el-input v-model="order.user.Province"></el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <!-- Post Code -->
                        <el-form-item prop="ZipCode">
                          <div class="contact_ZipCode_input">
                            <div class="orders_Post_code">
                              {{ $t('orders.Post_code') }}
                            </div>
                            <div class="order_user_ZipCode">
                              <el-input v-model="order.user.ZipCode"></el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <!-- Tracking No. -->
                        <div class="TrackingNo">
                          <div class="orders_TrackingNo_words">
                            {{ $t('orders.TrackingNumber') }}
                          </div>
                          <div class="order_TrackingNumber">{{ order.TrackingNumber }}</div>
                        </div>
                      </div>
                      <div class="RightForm">
                        <!-- Address1 -->
                        <el-form-item prop="Address1">
                          <div class="contact_Address_input">
                            <div class="orders_Address_words">{{ $t('orders.Address') }}</div>
                            <div class="REQUIRED">*</div>
                            <div class="order_user_Address">
                              <el-input v-model="order.user.Address1"></el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <!-- Address2 -->
                        <el-form-item prop="Address2">
                          <div class="contact_Address2_input">
                            <div class="orders_Address2_words">{{ $t('orders.Address2') }}</div>
                            <div class="order_user_Address2">
                              <el-input v-model="order.user.Address2"></el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <!-- Phone -->
                        <el-form-item prop="TelePhone">
                          <div class="contact_TelePhone_input">
                            <div class="orders_TelePhone_words">{{ $t('orders.Tel') }}</div>
                            <div class="REQUIRED">*</div>
                            <div class="order_user_TelePhone">
                              <el-input v-model="order.user.TelePhone"></el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <!-- City -->
                        <el-form-item prop="City">
                          <div class="contact_City_input">
                            <div class="REQUIRED">*</div>
                            <div class="orders_City_words">
                              {{ $t('orders.City') }}
                            </div>
                            <div class="order_user_City">
                              <el-input v-model="order.user.City"></el-input>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                  </el-form>
                  <!--  <div class="Save_btn" v-if="paymentList.IsAdmin == true">
                    <button
                      class="comBtn save btn-bg5"
                      v-if="!(order.OrderStatus === 'Paid')"
                      @click.stop="saveInfo(index)"
                    >
                      {{ $t("orders.save") }}
                    </button>
                  </div> -->
                </div>
              </template>
              <!-- 判断是否绑定店铺 -->
              <el-empty
                :image="require('../../assets/imgs/NavGuide/FrameStore.png')"
                v-if="
                  BindStoreList.IsStoreComplete == false &&
                  BindStoreList.IsOfflineStoreComplete == false
                "
              >
                <button class="AddStoreJiaHao comBtn btn-bg0" @click="AddStoreBtn">
                  <p>
                    <img
                      src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715592137077_AddStoreJiaHao.png"
                      alt=""
                    />
                  </p>
                  <p>{{ $t('orders.AddStore') }}</p>
                </button>
              </el-empty>
              <!-- 分页数据 -->
              <div class="pagebox">
                <el-pagination
                  layout="prev, pager, next,slot"
                  :page-size="pageSize"
                  :current-page="quotedList[0].page"
                  :page-sizes="[]"
                  @current-change="pageChange"
                  :total="quotedList[0].total"
                  @size-change="handleSizeChange"
                >
                  <span class="pageSizeInput">
                    <!-- <el-input class="pageSizeBorder" v-model.number="pageSize" @input="handlePageSize"></el-input> -->
                    <template>
                      <el-select v-model="pageSize" @change="selectChanged">
                        <el-option
                          v-for="item in optionsSize"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </span>
                </el-pagination>
                <div @keyup.enter="getPageList(0)" class="page_sign">
                  <span>{{ $t('orders.get') }}</span>
                  <input type="text" v-model="quotedList[0].jumpPage" />
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <!-- 支付 -->
      <div class="paybox" v-if="isPay">
        <div class="pay_content">
          <p class="pay_title">{{ $t('') }}<span @click="cancelPay"></span></p>
          <div class="pay_items">
            <div class="pay_item">
              <div class="pay_left">{{ $t('orders.account') }}</div>
              <div class="pay_right">{{ $t('orders.sum') }}{{ account.BalanceWithCredits }}</div>
            </div>
            <div class="pay_item">
              <div class="pay_left">{{ $t('orders.UcreditBalance') }}</div>
              <div class="pay_right">
                <span>U{{ account.CreditCoin }}</span>
                <span style="margin-left: 5px" v-if="payOrder.WanaPayCreditCoin !== 0"
                  >(-U{{ payOrder.WanaPayCreditCoin }})</span
                >
              </div>
            </div>
            <div class="pay_item">
              <div class="pay_left">{{ $t('orders.amount') }}</div>
              <div class="pay_right">{{ $t('orders.sum') }}{{ payOrder.OrdersAmount }}</div>
            </div>
            <div class="pay_item">
              <div class="pay_left">{{ $t('orders.Tax') }}:</div>
              <div class="pay_right">{{ $t('orders.sum') }}{{ payOrder.TaxAmount }}</div>
            </div>
            <div class="pay_item">
              <div class="pay_left" style="font-weight: 600">{{ $t('orders.GrandTotal') }}</div>
              <div class="pay_right">
                <span style="font-weight: 600">
                  {{ $t('orders.sum') }}
                  <span style="font-size: 22px">
                    {{ payOrder.WanaPayBalance }}
                  </span>
                  <span>
                    <span class="col-col2">
                      <span v-if="payOrder.TotalDeductionAmount > 0">
                        (Saved $ {{ payOrder.TotalDeductionAmount }})
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div class="Member_exclusive">
              <p class="col-col5"><span class="col-col7">*</span>{{ $t('orders.Included') }}</p>
            </div>
            <div class="pay_footer">
              <div class="comBtn border-col0 btn col-col0" @click="rechargeFun">
                {{ $t('orders.recharge') }}
              </div>
              <div
                v-show="!CenterMessage.AutoPaymentMode"
                class="comBtn btn-bg0 btn"
                @click="goPay()"
              >
                {{ $t('orders.pay1') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="member_box" v-if="memberPopupBox">
        <div class="member_set">
          <div class="member_content">
            <div class="member_top">
              <img
                src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715592188971_HuoJianAnYe.png"
                alt=""
              />
            </div>
            <div class="member_down">
              <p>{{ $t('orders.YouHave') }}</p>
              <p>{{ $t('orders.MoreOrders') }}</p>
            </div>
            <div class="Bindstore">
              <p>
                <span style="color: red; font-size: 20px">*</span>
                {{ $t('orders.BindMembership') }}
              </p>
            </div>
          </div>
          <div class="member_btn">
            <el-button @click="ComparePriceBtn">{{ $t('orders.ViewPlan') }}</el-button>
            <el-button @click="AddStoreBtn">{{ $t('orders.AddStore') }}</el-button>
          </div>
        </div>
      </div>
      <div v-if="showUpgrade">
        <div class="UploadOrderBox">
          <div class="UploadOrderContent upgradeDialog">
            <div class="btns">
              <div class="btn" @click="cancelUpgrade">Cancel</div>
              <div class="btn" @click="$router.push('/Upgrade')">Upgrade</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 上传订单模板弹窗 -->
      <div class="UploadOrderBox" v-if="UploadOrderBox">
        <div class="UploadOrderContent">
          <div class="uploadOrderTitle">
            <p>{{ $t('orders.UploadOrders') }}</p>
            <p @click="closeUploadBtn">
              <i class="el-icon-close"></i>
            </p>
          </div>
          <!-- 上传订单模板 -->
          <div class="Upload_order_template">
            <a href="/static/USAdropOrderImportTemplate.xlsx" download>
              <p>
                <img
                  src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715592266930_GroupDload.png"
                  alt=""
                />
              </p>
              <P>{{ $t('orders.UploadFile') }}</P>
            </a>
          </div>
          <div class="UploadFileContent">
            <div class="UploadFileBtn">
              <el-upload
                class="upload-demo"
                ref="upload"
                :action="action"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :auto-upload="false"
                :headers="headersaa"
                :on-success="handleSuccess"
                name="File"
                :limit="1"
              >
                <p @mouseenter="onMouseOver" @mouseleave="onMouseOut">
                  <el-button size="small" class="comBtn btn-bg5 col-col8">{{
                    $t('orders.ChooseFile')
                  }}</el-button>
                </p>
              </el-upload>
              <el-button style="margin-left: 10px" size="small" @click="submitUpload">
                {{ $t('orders.Submit') }}
              </el-button>
            </div>
          </div>
          <div class="TextareaContent">
            <p @clear="getGoodsList()">{{ UploadMessage }}</p>
          </div>
          <div class="Please_download" v-if="PleaseDownload">
            <p>
              {{ $t('orders.PleaseTemplate') }} <br />
              {{ $t('orders.corresponMandatory') }}
            </p>
          </div>
        </div>
      </div>
      <div id="toTop" @click="toTop(step)" class="BackTop">
        <p>
          <img
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715592306320_BackTopUp.png"
            alt=""
          />
        </p>
      </div>
      <!-- 未绑定店铺7天和会员费过期弹窗 -->
      <MemberBindBox ref="child"></MemberBindBox>
    </div>
  </div>
</template>
<script>
import MemberBindBox from '@/components/MemberBindBox.vue'
import lastedNews from '@/components/lastedNews.vue'
import { baseApiHost } from '../../utils/env'
import { mapState } from 'vuex'

export default {
  data: () => {
    return {
      dispatch: null,
      showUpgrade: false,
      url: 'https://webapi.usadrop.com/',
      PleaseDownload: false,
      page: 'orders', //导航栏选中
      banner: '', //面包屑数据
      itemname: '', //面包屑数据
      orderType: 1, //订单状态选择
      currentPage: 1, //分页选中页
      totalItems: 0,
      isMore: false, //是否展示高级搜索
      countrys: '', //高级搜索 国家
      stores: '', //高级搜索 店铺
      //selectTime: "", //高级搜索 时间
      selectTime: [],
      keywords: '', //关键字搜索
      selectCountry: '', //选中国家
      selectStore: '', //选中店铺
      UploadMessage: '',
      salesRecord: '',
      CustomerPayment: '',
      productName: '',
      contact: '',
      pageSize: 10,
      isPay: false, //支付弹窗
      orderlist: [], //订单列表
      payOrder: {}, //选中支付订单，全部时自定义
      orderIds: '', //支付订单id集合“,”分割
      result: [], // Tracking Number值
      MembershipInterests: [],
      memberPopupBox: false,
      pmgressbar: 0, // 进度条
      Fulfilled: true, // 进度条的显示与隐藏
      paymentList: [],
      // 表单验证
      rules: {
        Contact: [{ required: true, message: 'Field cannot be empty', trigger: 'blur' }],
        TelePhone: [{ required: true, message: 'Field cannot be empty', trigger: 'blur' }],
        Address1: [{ required: true, message: 'Field cannot be empty', trigger: 'blur' }],
        Province: [{ required: true, message: 'Field cannot be empty', trigger: 'blur' }],
        City: [{ required: true, message: 'Field cannot be empty', trigger: 'blur' }]
      },
      getOrder: '', // 点击li标签获取的索引
      Discount: false,
      quotedList: [
        //数据列表
        {
          type: 0,
          list: [],
          page: 1, //当前页
          pageSize: 10,
          total: 0, //总条数
          jumpPage: 1 //跳转页数
        }
      ],
      dialogForm: {
        OrderTimeStart: '',
        OrderTimeEnd: ''
      },
      oldQuotePrice: 0,
      UploadOrderBox: false,
      textarea: '',
      fileList: [],
      json_fields: {},
      json_data: [],
      headersaa: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization')
      }, // token
      DisplayShowWords: false,
      // ShowProductName: false,
      scrollTop: 0, //默认距离顶部的距离
      isShow: false, //控制返回顶部按钮的显隐
      scrollTrigger: false, //默认初始值
      isActive: false,
      BindStoreList: [], //是否绑定店铺
      optionsSize: [
        {
          value: 10,
          label: '10'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 100,
          label: '100'
        }
      ],
      CenterMessage: [],
      action: baseApiHost() + '/api/Order/ImportOfflineOrders',
      types: ['orders.all', 'orders.Quoting', 'orders.Quoted', 'orders.paid'],
      currentIndex: 0,
      typeUpgrade: 'single',
      optionsType: [
        {
          value: 'authorized',
          label: 'authorized'
        },
        {
          value: 'paid',
          label: 'paid'
        },
        {
          value: 'partially_paid',
          label: 'partially_paid'
        },
        {
          value: 'partially_refunded',
          label: 'partially_refunded'
        },
        {
          value: 'pending',
          label: 'pending'
        },
        {
          value: 'refunded',
          label: 'refunded'
        },
        {
          value: 'voided',
          label: 'voided'
        }
      ],
      PlatformStatus: ''
    }
  },
  components: {
    lastedNews,
    MemberBindBox
  },
  computed: {
    ...mapState(['theme', 'userInfo', 'account'])
  },
  props: {
    step: {
      //控制速度
      type: Number,
      default: 40
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    this.getDataList() // 判断是否自动付款
    //获取面包屑渲染
    this.banner = this.$t('orders.bannername')
    this.itemname = this.$t('orders.itemname')
    this.CheckGuide() // 是否绑定店铺
    this.getOrderList()
    this.Membership()
    this.More7daysNoStoreOrNoGrade()
    //this.globalClick(this.CloseBtn);
    const _self = this
    // document.onkeydown = function (e) {
    //   let key = window.event.keyCode
    //   if (key === 13) {
    //     _self.getOrderList()
    //     /* console.log(e, '123') */
    //   }
    // }
    window.addEventListener('keydown', this.keyDown)
    window.addEventListener('scroll', this.handleScroll)
  },
  watch: {
    releaseTime: {
      handler: function (newVal, oldVal) {
        if (newVal === null) {
          this.selectTime = []
        }
      },
      deep: true
    }
  },
  methods: {
    keyDown(e) {
      let key = e.keyCode
      if (key === 13) {
        this.getOrderList()
      }
    },
    onMouseOver() {
      this.PleaseDownload = true
    },
    onMouseOut() {
      this.PleaseDownload = false
    },
    /* 获取客户基本信息（判断是否自动判断） */
    getDataList() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.CenterMessage = res?.Result
          this.paymentList = res?.Result
          this.rawData = res?.Result
          this.$store.commit('setAccount', res.Result)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 是否绑定店铺 */
    CheckGuide() {
      this.dispatch('member/CheckGuideMe')
        .then((res) => {
          this.BindStoreList = res.Result
          // 价格重置
          //console.log(res.Result, '123');
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleScroll(e) {
      this.isActive = document.documentElement.scrollTop < 200
    },
    More7daysNoStoreOrNoGrade() {
      this.dispatch('guide/checkGuideGu')
        .then((res) => {
          this.BindStoreList = res.Result
          if (res.Result.More7daysNoStoreOrNoGrade) {
            this.$refs.child.handelMember()
          } else {
            // this.goDetail();
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toTop(step) {
      console.log(step, '6666666')
      //参数step表示时间间隔的幅度大小，以此来控制速度
      //当回到页面顶部的时候需要将定时器清除
      document.getElementById('usaContainer').scrollTop -= step
      if (document.getElementById('usaContainer').scrollTop > 0) {
        var time = setTimeout(() => this.toTop(step), 15)
      } else {
        clearTimeout(time)
      }
    },
    enter: function () {
      this.DisplayShowWords = true
    },
    leave: function () {
      this.DisplayShowWords = false
    },
    enterProduct: function (detail) {
      detail.ShowProductName = true
      this.$forceUpdate()
    },
    leaveProduct: function (detail) {
      detail.ShowProductName = false
      this.$forceUpdate()
    },
    ExportExcelBtn() {
      // 根据条件获取订单列表
      this.dispatch('order/ExportOrderOd', {
        Keywords: this.keywords,
        Filter: {
          TabType: this.orderType,
          UserId: this.selectStore,
          SalesRecord: this.salesRecord,
          CustomerPayment: this.CustomerPayment,
          ProductName: this.productName,
          Contact: this.contact,
          CountryCode: this.selectCountry,
          OrderTimeStart: this.selectTime ? this.selectTime[0] : '',
          OrderTimeEnd: this.selectTime ? this.selectTime[1] : '',
          CustomerPayment: this.PlatformStatus
        }
      })
        .then((res) => {
          if (res.Success) {
            window.open(baseApiHost() + res.ErrMsg)
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 跳转到绑定店铺页面 */
    AddStoreBind() {
      this.$router.push('Store')
    },
    submitUpload() {
      this.$refs.upload.submit()
    },
    handleSuccess(res, file) {
      if (res.MapData.errorMessage != null) {
        this.UploadMessage = res.MapData.errorMessage
      } else {
        this.UploadMessage = res.ErrMsg
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    getGoodsList() {
      this.UploadMessage = ''
    },
    handlePreview(file) {
      console.log(file)
    },
    /* 单个添加订单信息 */
    AddOrder() {
      if (this.BindStoreList.More7daysNoStoreOrNoGrade == true) {
        this.More7daysNoStoreOrNoGrade()
      } else {
        this.$router.push('OfflineOrder')
      }
    },
    UploadOrder() {
      if (this.BindStoreList.More7daysNoStoreOrNoGrade == true) {
        this.More7daysNoStoreOrNoGrade()
      } else {
        this.getGoodsList()
        this.UploadOrderBox = true
      }
    },
    closeUploadBtn() {
      this.UploadOrderBox = false
    },
    /* 显示公告 */
    NoticeBtn() {
      this.$router.push('ImportantNotices')
    },
    Membership() {
      let that = this
      this.dispatch('order/GetRightsWithProcessOrderOd')
        .then((res) => {
          that.MembershipInterests = res.Result
          if (this.MembershipInterests.TotalTimes == '-1') {
            this.Fulfilled = false
          }
          that.pmgressbar =
            (that.MembershipInterests.UsedTimes / that.MembershipInterests.TotalTimes) * 100
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ComparePriceBtn() {
      this.$router.push('/Upgrade')
    },
    MonthlyRed() {
      this.$router.push('/Upgrade')
    },
    AddStoreBtn() {
      this.$router.push('/Store')
    },
    CloseBtn(event) {
      this.memberPopupBox = false
    },
    menterData(index) {
      this.payOrder = this.orderlist[index]
      this.orderIds = this.orderlist[index].OrderId
      this.dispatch('order/GetOrderPaymentsAmountOd', this.orderIds)
        .then((res) => {
          if (res?.Success && res?.Result) {
            this.OrdersAmount = this.payOrder.OrdersAmount
            this.payOrder.OrdersAmount = res.Result.OrdersAmount
            this.WanaPayAmount = this.payOrder.WanaPayAmount
            this.payOrder.TaxAmount = res.Result.TaxAmount
            this.payOrder.WanaPayCreditCoin = res.Result.WanaPayCreditCoin
            this.payOrder.WanaPayCreditCoin = res.Result.WanaPayCreditCoin
            this.payOrder.WanaPayBalance = res.Result.WanaPayBalance
            this.CardAmount = this.payOrder.CardAmount
            this.payOrder.CardAmount = res.Result.CardAmount
            this.payOrder.TotalDeductionAmount = res.Result.TotalDeductionAmount
          }
          this.isPay = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    del() {
      console.log(11111111111)
    },
    selectType(type) {
      //改变订单筛选类型
      this.orderType = type
      this.getOrderList()
    },
    handleSizeChange(pageSize) {
      this.quotedList[0].pageSize = pageSize
      this.getOrderList()
    },
    handlePageSize() {
      if (!this.quotedList[0].pageSize) {
        this.quotedList[0].pageSize = 0
      }
    },
    selectChanged(pageSize) {
      this.quotedList[0].pageSize = pageSize
      this.getOrderList()
    },
    pageChange(page) {
      //页数改变
      let that = this
      that.quotedList[0].page = page
      that.getOrderList()
    },
    getPageList(type) {
      //分页器跳转页数
      this.quotedList[type].page =
        this.quotedList[type].jumpPage > 0 ? Number(this.quotedList[type].jumpPage) : 1
      this.getOrderList()
    },
    getOrderList() {
      // 根据条件获取订单列表
      let that = this
      this.result = []
      let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.dispatch('order/GetOrderListTwoOd', {
        CurrentPageIndex: that.quotedList[0].page,
        PageSize: that.quotedList[0].pageSize,
        Keywords: that.keywords,
        Filter: {
          TabType: that.orderType,
          UserId: that.selectStore,
          SalesRecord: that.salesRecord,
          CustomerPayment: this.CustomerPayment,
          ProductName: this.productName,
          Contact: this.contact,
          CountryCode: that.selectCountry,
          OrderTimeStart: that.selectTime ? that.selectTime[0] : '',
          OrderTimeEnd: that.selectTime ? that.selectTime[1] : '',
          CustomerPayment: this.PlatformStatus
        }
      })
        .then((res) => {
          loading.close()
          if (res.Success) {
            res.Items.map((item) => {
              item.isInfo = false
              item.user = {} //定义用户信息
            })
            that.orderlist = res.Items
            that.orderlist.map((item) => {
              item.Contact = '***' + item.Contact.substr(-2)
            })
            /*  that.orderlist.Contact = "***" + that.orderlist.Contact.substr(-2); */
            let list = that.quotedList
            list[0].list = res.Items
            that.orderlist.map((item) => {
              item.QuotedPrice = item.QuotedPrice.toFixed(2)
              if (item.TrackingNumber != null) {
                var result = item.TrackingNumber.split(',')
                for (var i = 0; i < result.length; i++) {
                  this.result.push(result[i])
                }
              }
              item.OrderDetailVos.map((v) => {
                v.ShowProductName = false
              })
            })
            list[0].total = res.TotalItems
            that.quotedList = list
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getOrderDetail(index) {
      // 查看订单详情
      this.getOrder = index
      let that = this
      let list = that.orderlist
      list.map((item, i) => {
        if (i == index && !item.OrderDetailVos) {
          this.dispatch('order/GetOrderVoByOrderIdOd', {
            orderId: that.orderlist[index].OrderId
          })
            .then((res) => {
              if (res.Success) {
                console.log()
                res.Result.OrderDetailVos.map((item) => {
                  item.QuotedPrice = item.QuotedPrice.toFixed(2)
                })
                item.OrderDetailVos = res.Result.OrderDetailVos
                item.isInfo = false // 展开商品时收起信息
                that.orderlist[index].QuotedPrice = res.Result.QuotedPrice.toFixed(2)
                that.$forceUpdate() //强制渲染
              } else {
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          item.OrderDetailVos = null
          that.$forceUpdate() //强制渲染
        }
      })
    },
    cancelItem(index, item) {
      // 取消订单单件商品
      let that = this
      let IsExclude = that.orderlist[index].OrderDetailVos[item].IsExclude
      IsExclude = IsExclude ? 0 : 1
      if (IsExclude == 1) {
        this.$confirm(this.$t('orders.AreCancel'), {
          confirmButtonText: this.$t('orders.Yes'),
          cancelButtonText: this.$t('orders.No'),
          customClass: this.$store.state.theme == 'dark' ? 'dark-box' : '',
          confirmButtonClass: this.$store.state.theme == 'dark' ? 'dark-btn' : '',
          cancelButtonClass: this.$store.state.theme == 'dark' ? 'dark-button' : '',
          closeOnPressEscape: true
          /* type: 'warning' */
        })
          .then(() => {
            this.dispatch('order/ExchangeItemStatusOd', {
              orderDetailId: that.orderlist[index].OrderDetailVos[item].OrderDetailId,
              isExclude: IsExclude
            })
              .then((res) => {
                if (res.Success) {
                  setTimeout(() => that.getOrderList(), 1000)
                } else {
                  that.$message.error(res.ErrMsg)
                }
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch(() => {
            this.$message.info(this.$t('orders.DeletionCancelled'))
          })
      } else {
        this.dispatch('order/ExchangeItemStatusOd', {
          orderDetailId: that.orderlist[index].OrderDetailVos[item].OrderDetailId,
          isExclude: IsExclude
        })
          .then((res) => {
            if (res.Success) {
              setTimeout(() => that.getOrderList(), 1000)
            } else {
              that.$message.error(res.ErrMsg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    cancelOrder(index) {
      // 取消订单 暂无此功能
      this.orderlist.splice(index, 1)
    },
    goToUpgrade(index) {
      this.currentIndex = index
      this.typeUpgrade = 'single'
      let tax = this.orderlist[index].SalesTaxAmount
      if (this.paymentList.GradeId !== 2 && this.paymentList.GradeId !== 3 && tax !== 0) {
        this.showUpgrade = true
      } else {
        this.getStatus()
      }
    },
    payAllUpgrade() {
      this.typeUpgrade = 'all'
      // if (this.paymentList.GradeId !== 2 && this.paymentList.GradeId !== 3) {
      //   this.showUpgrade = true
      // } else {
      //   this.payAll()
      // }
      this.payAll()
    },
    cancelUpgrade() {
      this.showUpgrade = false
      if (this.typeUpgrade === 'all') {
        // this.payAll()
        this.isPay = true
      } else {
        this.getStatus()
      }
    },
    getStatus() {
      this.getDataList()
      if (this.MembershipInterests.Enabled == true) {
        if (this.orderType === 4) {
          return
        }
        this.menterData(this.currentIndex)
        this.memberPopupBox = false
      } else {
        this.memberPopupBox = true
      }
    },
    showUser(index) {
      // 获取订单用户信息
      let that = this
      this.dispatch('order/GetAddressOd', {
        addressId: that.orderlist[index].AddressId
      })
        .then((res) => {
          if (res.Success) {
            that.orderlist[index].user = res.Result
            that.orderlist[index].user.Contact = that.orderlist[index].user.Contact
              ? '*****' + that.orderlist[index].user.Contact.substr(-2)
              : that.orderlist[index].user.Contact
            that.orderlist[index].user.Address1 = that.orderlist[index].user.Address1
              ? '*****' + that.orderlist[index].user.Address1.substr(-2)
              : that.orderlist[index].user.Address1
            that.orderlist[index].user.Address2 = that.orderlist[index].user.Address2
              ? '*****' + that.orderlist[index].user.Address2.substr(-2)
              : that.orderlist[index].user.Address2
            var length = that.orderlist[index].user.TelePhone.length
            var start = Math.floor(length / 4) // 地址长度的四分之一处
            var end = length - start // 地址长度减去四分之一处
            var stars = '*'.repeat(end - start)
            var maskedAddress =
              that.orderlist[index].user.TelePhone.substring(0, start) +
              stars +
              that.orderlist[index].user.TelePhone.substring(end)
            that.orderlist[index].user.TelePhone = maskedAddress
            that.getCountrys()
          } else {
            that.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      if (!that.orderlist[index].isInfo) {
        // 展开信息时收起商品
      }
      that.orderlist[index].isInfo = !that.orderlist[index].isInfo
    },
    saveInfo(index) {
      // 保存信息
      let that = this
      let formname = 'form' + index
      this.$refs[formname][0].validate((valid) => {
        if (valid) {
          this.dispatch('order/UpdateAddressOd', that.orderlist[index].user)
            .then((res) => {
              if (res.Success) {
                that.$message.success(that.$t('orders.modify'))
              } else {
                that.$message.error(res.ErrMsg)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    payAll() {
      // 批量支付
      let that = this
      if (this.MembershipInterests.Enabled == true) {
        let loading = this.$loading({
          lock: true,
          spinner: 'el-icon-loading',
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.dispatch('member/GetMemberVoMe')
          .then((res) => {
            that.$store.commit('setAccount', res.Result)
            this.dispatch('order/GetOrderPaymentsAmountBySearchOd', {
              CurrentPageIndex: that.quotedList[0].page,
              PageSize: that.quotedList[0].pageSize,
              Keywords: that.keywords,
              Filter: {
                TabType: that.orderType,
                UserId: that.selectStore,
                CountryCode: that.selectCountry,
                OrderTimeStart: that.selectTime ? that.selectTime[0] : '',
                OrderTimeEnd: that.selectTime ? that.selectTime[1] : ''
              }
            })
              .then((res) => {
                loading.close()
                if (res.Success) {
                  this.payOrder = { ...res.Result, type: 'payAll' }
                  this.orderIds = res.Result?.OrderIds
                  // 支付弹窗
                  // this.isPay = true
                  if (
                    this.paymentList.GradeId !== 2 &&
                    this.paymentList.GradeId !== 3 &&
                    res.Result?.TaxAmount !== '0.00'
                  ) {
                    this.showUpgrade = true
                  } else {
                    // this.payAll()
                    this.isPay = true
                  }
                } else {
                  that.$message.error(res.ErrMsg)
                }
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
        that.memberPopupBox = false
      } else {
        that.memberPopupBox = true
      }
    },

    cancelPay() {
      if (this.oldQuotePrice > 0) {
        this.payOrder.OrdersAmount = this.oldQuotePrice
      }
      this.isPay = false
    },
    rechargeFun() {
      this.isPay = false
      this.$router.push('/wallet')
    },
    stopClick() {
      // 阻止冒泡
      return
    },
    getOrders: function () {
      let routeData = this.$router.resolve({
        path: '/synchronize'
      })
      window.open(routeData.href, '_blank')
    },
    goPay() {
      // 订单支付
      let that = this
      let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      that.isPay = false
      this.dispatch('order/SaveOrderPaymentsOd', that.orderIds || '')
        .then((res) => {
          loading.close()
          //console.log(loading.close, '11111223456')
          if (res.Success) {
            that.getOrderList()
            this.Membership()
            that.isPay = false //关闭弹窗
            that.orderIds = 'OrderIds'
          } else {
            that.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      //单笔支付后，弹出支付全部的弹框么？而且这里也不是刷新页面的时机吧
      //this.payAll();
    },
    toggleMore() {
      // 切换高级搜索
      let that = this
      that.isMore = !that.isMore
      if (!that.countrys) {
        that.getCountrys()
      }
      if (!that.stores) {
        that.getStores()
      }
    },
    getCountrys() {
      // 获取高级搜索国家
      this.dispatch('order/GetCountryListOd')
        .then((res) => {
          this.countrys = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getStores() {
      // 获取高级搜索 店铺搜索
      this.dispatch('store/GetListSt')
        .then((res) => {
          this.stores = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    timeChange() {
      // 高级搜索时间改变
      // console.log(this.selectTime);
      if (this.selectTime.length) {
        // 取值
        this.dialogForm.OrderTimeStart = this.selectTime[0]
        this.dialogForm.OrderTimeEnd = this.selectTime[1]
      } else {
        this.dialogForm.OrderTimeStart = ''
        this.dialogForm.OrderTimeEnd = ''
      }
    },
    moreSearch() {
      // 多条件搜索
      this.isMore = false
      this.quotedList[0].page = 1
      this.getOrderList()
    },
    cancelSearch() {
      // 取消多条件搜索
      this.isMore = false
      this.selectTime = ''
      this.selectStore = ''
      this.selectCountry = ''
      this.productName = ''
      this.contact = ''
      this.salesRecord = ''
      this.CustomerPayment = ''
      this.PlatformStatus = ''
      this.getOrderList()
    }
    /*  enterTrackingNumber: function (index) {
      this.ShowTrackingNumber = true;
      this.$forceUpdate();
    },
    leaveTrackingNumber: function (index) {
      this.ShowTrackingNumber = false;
      this.$forceUpdate();
    }, */
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDown)
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang="scss" scoped>
$btn-bg5: #3f6aff;
@mixin flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.UploadOrderBox {
  .UploadOrderContent.upgradeDialog {
    background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715593585615_pay_upgrade_img.svg')
      no-repeat;
    background-size: 496px 316px;
    width: 496px;
    height: 316px;
    z-index: 100;
    position: relative;
    padding: 0 76px;
    .btns {
      position: absolute;
      bottom: 70px;
      display: flex;
      // width: %;
      align-items: center;
      justify-content: space-between;
      .btn {
        width: 160px;
        height: 40px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        &:first-child {
          background: #dcdfe6;
          color: #15104b;
          margin-right: 24px;
        }
        &:nth-child(2) {
          background: #15104b;
          color: #fff;
        }
      }
    }
  }
}

.min-wid100 {
  min-width: 100px;
}
.min-wid200 {
  min-width: 200px;
}
.plain {
  background: none;
  border: none;
}
.searchbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 20px;
  margin-bottom: 20px;
  .search_input {
    display: block;
    width: 450px;
    height: 40px;
    background: #f1f2f4;
    border-radius: 30px;
    margin-right: 20px;
    .search_icon {
      display: inline-block;
      margin-top: 15px;
      margin-right: 24px;
      margin-left: 24px;
    }
    input {
      background: transparent !important;
      width: calc(100% - 70px);
      font-size: 16px;
      color: #585858;
      padding-right: 20px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
  }
  .topbox {
    div {
      min-width: 100px;
      height: 40px;
      margin-right: 20px;
    }
    .Synchronize a {
      color: #fff;
      font-size: 16px;
    }
  }
  .add_shopify a{
    color: #fff;
    font-size: 16px;
  }
}
.typebox-btn-group .all:hover {
  background: #3f6aff;
  color: #fff !important;
}
.typebox-btn-group {
  text-align: right;
  .all {
    height: 40px;
    min-width: 150px;
    border-width: 2px;
    border-style: solid;
    margin-top: 20px;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
.main-list {
  background-color: #fff;
  li {
    & :hover {
      background-color: #eff1f3;
    }
    .content {
      border-bottom: 2px solid #f2f2f2;
      .title_li {
        font-family: 'Regular';
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-font-smoothing: antialiased;
        &:nth-child(1) {
          width: 125px;
        }
        &:nth-child(2) {
          width: 170px;
        }
        &:nth-child(3) {
          width: 200px;
        }
        &:nth-child(4) {
          width: 180px;
        }
        &:nth-child(5) {
          width: 160px;
        }
        &:nth-child(6) {
          width: 100px;
        }
        &:nth-child(7) {
          width: 150px;
          padding-left: 15px;
        }
        &:nth-child(8) {
          width: 200px;
        }
        &:nth-child(9) {
          width: 100px;
        }
      }
    }
    .contentMessage {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      font-size: 14px;
      color: #000000;
      height: auto;
      width: 100%;
      overflow-x: scroll;
      .content_li {
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #f2f2f2;
        padding: 0 10px;
        &:nth-child(1) {
          width: 160px;
        }
        &:nth-child(2) {
          width: 160px;
        }
        &:nth-child(3) {
          width: 240px;
          flex-direction: column;
        }
        &:nth-child(4) {
          color: $btn-bg5;
          width: 190px;
          span {
            margin-left: 5px;
            width: 15px;
            height: 15px;
            background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715593618161_blue.png')
              no-repeat;
            background-size: cover;
            -webkit-background-size: cover;
            transition: all 0.3s linear;
          }
        }
        &:nth-child(5) {
          width: 160px;
        }
        &:nth-child(6) {
          width: 160px;
        }
        &:nth-child(7) {
          width: 160px;
        }
        &:nth-child(8) {
          width: 160px;
        }
        &:nth-child(9) {
          width: 160px;
        }
      }
    }
  }
}
.type {
  height: 80px;
  line-height: 72px;
  padding: 0 8px;
  font-size: 16px;
  float: left;
  margin-right: 60px;
  position: relative;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.select {
  color: $btn-bg5;
  font-size: 16px;
  line-height: 72px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 17px;
    width: 60%;
    height: 2px;
    background: $btn-bg5;
    left: 20%;
  }
}
::v-deep .mydialog {
  border-radius: 20px !important;
}

::v-deep .el-message-box__message p {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .pay_content {
    width: 670px;
    background: #fff;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    // overflow: hidden;
    position: absolute;
    padding-bottom: 40px;
    left: calc(50% - 335px);
    top: calc(50% - 260px);
    .pay_title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      color: #000;
      padding-left: 42px;
      padding-right: 32px;
      // background: #ffffff;
      span {
        width: 15px;
        height: 16px;
        display: block;
        background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715593643566_close.png')
          no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        float: right;
        margin-top: 20px;
        cursor: pointer;
      }
    }
    .pay_items {
      width: 100%;
      font-size: 16px;
      color: #000000;
      @include flex;
      flex-direction: column;
      .pay_item {
        width: 100%;
        @include flex;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 20px;
        }
        .pay_left {
          width: 50%;
          @include flex;
          justify-content: flex-end;
          padding-right: 60px;
        }
        .pay_right {
          padding-left: 60px;
          width: 50%;
          @include flex;
          justify-content: flex-start;
        }
      }
      .Member_exclusive {
        font-size: 14px;
        margin-bottom: 40px;
      }
      .pay_footer {
        @include flex;
        .btn {
          width: 200px;
          height: 44px;
          &:first-child {
            border-style: solid;
            border-width: 2px;
            margin-right: 100px;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.el-empty {
  background: #fff;
}
.questionIcon {
  position: absolute;
  bottom: 130px;
  right: 50px;
}

.questionIcon >>> .el-icon-question {
  font-size: 22px;
}

/* 查询订单对话框 */
.IncludedDiscount {
  width: 295px;
  height: 40px;
  background: #aeaeae;
  z-index: 21474899 !important;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  bottom: 50px;
  left: 280px;
}

.IncludedDiscount p {
  /*color: #0000EE;*/
  color: #fff;
  margin-left: 15px;
  margin-top: 5px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  line-height: 25px;
}

.IncludedDiscount::before {
  content: '';
  position: absolute;
  bottom: -15%;
  right: 40px;
  width: 22px;
  height: 22px;
  margin-top: -10px;
  background: inherit; /*自动继承父元素的背景*/
  transform: rotate(45deg);
}

.IncludedDiscount {
  position: absolute;
  bottom: 125px;
  right: 30px;
}

.AddStoreJiaHao {
  width: 300px;
  height: 50px;
  position: relative;
  margin-top: 0px;
}

/* .AddStoreJiaHao:hover {
  background: #93df89;
  border: 1px solid #93df89;
} */

.AddStoreJiaHao p:nth-child(1) {
  position: absolute;
  top: 5px;
  left: 80px;
}

.AddStoreJiaHao p:nth-child(1) img {
  width: 40px;
  height: 40px;
}

.AddStoreJiaHao p:nth-child(2) {
  position: absolute;
  top: 12px;
  left: 130px;
  font-size: 22px;
  color: #fff;
}

.list >>> .el-empty__description p {
  display: none;
}

.pagebox >>> .el-pagination .el-select .el-input .el-input__inner {
  border: 1px solid #ccc;
  height: 35px;
  border-radius: 10px;
  margin-left: 10px;
}

.MonthlyRed {
  position: absolute;
  top: 0px;
  left: 200px;
  cursor: pointer;
}

.MonthlyRed img {
  width: 20px;
  height: 20px;
}

.MonthlyRed span img:hover {
  transform: scale(1.2);
}

/* 线上导入订单 */
.UploadOrderBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.UploadOrderContent {
  width: 600px;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  /* overflow: hidden;*/
  left: calc(50% - 300px);
  top: calc(50% - 400px);
  margin-top: 160px;
  /*background-color: #fff;*/
  background-color: #ffffff;
  height: auto;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-bottom: 20px;
}

.uploadOrderTitle {
  background: #21224e;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  height: 45px;
  justify-content: space-between;
}

.uploadOrderTitle p:nth-child(1) {
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  line-height: 40px;
  margin-left: 30px;
}
.uploadOrderTitle p:nth-child(2) >>> .el-icon-close {
  color: #fff;
  font-size: 24px;
  margin-right: 15px;
  line-height: 40px;
  cursor: pointer;
}
.Upload_order_template {
  width: 165px;
  height: 130px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #d1d1d1;
  text-align: center;
  margin-left: 230px;
  margin-top: 50px;
}
.Upload_order_template p:nth-child(1) {
  width: 50px;
  height: 50px;
  border: 2px dashed #999999;
  border-style: dashed;
  margin-left: 60px;
  margin-top: 30px;
}
.Upload_order_template p:nth-child(2) {
  color: #999999;
  font-size: 14px;
  margin-top: 5px;
}
.UploadFileBtn {
  display: flex;
  margin-left: 115px;
  margin-top: 20px;
}
.UploadFileBtn >>> .el-button:nth-child(1) {
  width: 180px;
  height: 44px;
  background: #ffffff;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  border: 2px solid #15104b;
  background: #fff !important;
  color: #15104b !important;
  font-weight: 600;
  font-size: 16px;
}
::v-deep .upload-demo {
  width: 180px;
}
::v-deep .el-upload-list__item-name {
  width: auto;
}
.UploadFileBtn >>> .el-button:nth-child(2) {
  width: 180px;
  height: 44px;
  background: #15104b;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  color: #ffffff;
  margin-left: 50px !important;
  font-size: 16px;
}
.Please_download {
  margin-left: 20px;
  margin-top: 20px;
}
.Please_download p {
  color: #666666;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.TextareaContent {
  width: 580px;
  margin-left: 10px;
  margin-top: 10px;
  word-break: normal;
}
.TextareaContent p {
}
.member_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.member_set {
  width: 380px;
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
  /* overflow: hidden;*/
  left: calc(60% - 400px);
  top: calc(50% - 450px);
  margin-top: 160px;
  /*background-color: #fff;*/
  background-color: #2a293e;
  height: 500px;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.member_top {
  width: 230px;
  height: 220px;
  /* background: orangered; */
  margin-left: 50px;
}

.member_top img {
  width: 100%;
  height: 100%;
  margin-top: 35px;
  margin-left: 20px;
}

.member_down {
  width: 380px;
  /*height: 200px;
    background: orange; */
  margin-top: 0px;
}

.member_down p:nth-child(1) {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  /* font-weight: 600;*/
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}

.member_down p:nth-child(2) {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}

.Bindstore p:nth-child(1) {
  color: #fff;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 30px;
  text-align: center;
  line-height: 40px;
  word-break: normal;
}

.member_btn {
  margin-left: 55px;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border-radius: 50px;
  margin-top: 20px;
  display: flex;
}

.member_btn >>> .el-button:nth-child(1) {
  margin-left: 5px;
  height: 45px;
  margin-bottom: -150px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn >>> .el-button:nth-child(2) {
  margin-left: 50px;
  height: 45px;
  margin-bottom: -150px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}

.member_btn >>> .el-button:hover {
  background: #bff4b8;
  color: #fff;
}

::v-deep .el-message-box__message p {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.speed-progress {
  width: 220px;
  position: relative;
  left: 500px;
  top: -50px;
}

.progress-fulfilled span:nth-child(1) {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.progress-fulfilled span:nth-child(2) {
  font-size: 14px;
  margin-left: 30px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.progress-fulfilled span:nth-child(3) {
  font-size: 14px;
  margin-left: 5px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.progress-fulfilled span:nth-child(4) {
  font-size: 14px;
  margin-left: 6px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-progress__text {
  font-size: 14px;
  color: #606266;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  line-height: 1;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  /* display: none; */
}

::v-deep .element.style {
  margin-top: -15px;
}

.fulfillment {
  width: 150px;
  height: 0px;
}

::v-deep .pro_detail.el-message-box__wrapper.el-message-box {
  display: inline-block;
  width: 500px;
  padding-bottom: 10px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 200px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.asteriskContact {
  width: 10px;
  height: 10px;
  line-height: 15px;
  color: red;
  position: absolute;
  left: 185px;
  top: 12px;
}

.asteriskTelePhone {
  width: 10px;
  height: 10px;
  line-height: 15px;
  color: red;
  position: absolute;
  right: 418px;
  top: 10px;
}

.asteriskAddress1 {
  width: 10px;
  height: 10px;
  line-height: 15px;
  color: red;
  position: absolute;
  right: 405px;
  top: 10px;
}

.asteriskProvince {
  width: 10px;
  height: 10px;
  line-height: 15px;
  color: red;
  position: absolute;
  left: 142px;
  top: 10px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.asteriskCity {
  width: 10px;
  height: 10px;
  line-height: 15px;
  color: red;
  position: absolute;
  right: 435px;
  top: 10px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.SpecBox {
  display: flex;
  justify-content: flex-end;
}

.SpecBox span:nth-child(1) {
  height: 90px;
  font-size: 14px;
  line-height: 90px;
  color: #858585;
  margin-right: 10px;
}

.SpecBox span:nth-child(2) {
  height: 90px;
  font-size: 14px;
  line-height: 90px;
  color: #858585;
  margin-right: 200px;
}

.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
}

.main {
  width: 1400px;
  padding: 0 0 32px;
}

.breadCrumb {
  padding-top: 60px;
  padding-left: 20px;
}
.Contact_input {
  width: 400px;
  height: 40px;
  border: 1px solid #ccc;
  float: left;
  color: #585858;
  text-align: center;
  border-radius: 50px;
  padding: 5px 20px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Contact_input input,
::v-deep .el-input__inner,
::v-deep .el-input {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 30px;
  background: none;
  color: #585858;
  padding: 0;
  border: none;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.myselect,
::v-deep .myselect.el-date-editor {
  width: 400px;
  height: 46px;
  background: #f1f1f2;
  padding: 0 15px;
  border-radius: 50px;
  color: #585858;
  display: inline-block;
  margin-right: 25px;
}

::v-deep .myselect.el-date-editor {
  width: 400px;
}

::v-deep .myselect input {
  font-size: 16px;
  background: #f1f1f2;
  cursor: pointer;
}

/* ::v-deep . */
::v-deep .myselect input::-webkit-input-placeholder {
  color: #858585;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 10px;
}

::v-deep .myselect input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #858585;
}

::v-deep .myselect input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #858585;
}

::v-deep .myselect input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #858585;
}

::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}

.typebox {
  width: 100%;
  padding: 0 32px;
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  overflow: hidden;
  position: relative;
}

.type:hover {
  color: #3f6aff !important;
}
.CanceledBtn {
  border-radius: 25px;
  min-width: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #000;
  height: 40px;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 18px;
  font-size: 16px;
  text-align: center;
  background-color: #dfe2e7;
}
.CanceledBtn:hover {
  opacity: 0.9;
}

/* 绑定店铺 */
.allStore {
  width: 130px;
  height: 40px;
  background: rgb(147, 223, 137);
  border-radius: 10px;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  float: right;
  margin-top: 20px;
  cursor: pointer;
  margin-right: 10px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.allStore:hover {
  background: #bff4b8;
  color: #fff;
}

.list {
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.list li {
  background: #fff;
  border-bottom: 2px solid #f2f2f2;
}

/* .list li:last-child { */
/* border: none; */
/* } */
.productListContentImg {
  margin-top: 15px;
}

.productListContentBox {
  display: flex;
}

.productListContent {
  display: flex;
  flex-direction: column;
  height: auto;
  &:hover .cancel-btn {
    display: block;
  }
}

.cancel-btn {
  display: none;
}

.productListProductName {
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
  margin-top: 18px;
}

.ShowProductListProductName {
  width: auto;
  height: auto;
  border: 1px solid #fff;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 60px;
  position: absolute;
  background: #fff;
}

.productListProductNumber {
  margin-left: 10px;
  margin-top: 5px;
}
.podTag {
  width: 48px;
  height: 24px;
  margin-left: 10px;
  margin-top: 5px;
  background: #ffeeee;
  font-size: 12px;
  font-weight: 600;
  color: #d40000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.NoNeedToShip {
  margin-left: 10px;
  margin-top: 5px;
}
.content {
  width: 100%;
  height: 82px;
  line-height: 82px;
  /* font-size: 16px; */
  font-size: 14px;
  color: #000000;
  text-align: center;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: row;
}

::-webkit-scrollbar {
  display: none;
}

.copper_coin {
  text-decoration: line-through;
  color: red;
}

.info_down {
  transform: rotate(90deg);
}

.USAdrop_payment {
  width: 400px;
  height: 50px;
  /* background: pink; */
  position: absolute;
  left: 220px;
  bottom: 10px;
}

.USAdrop_payment span:nth-child(1) {
  margin-left: -65px;
  color: #898989;
  font-size: 14px;
}

.USAdrop_payment span:nth-child(2) {
  margin-left: 55px;
  color: #898989;
  font-size: 16px;
}

.order_status .order_btn {
  float: none;
  display: inline-block;
}

.order_status .success {
  /* min-width: 135px; */
  min-width: 100px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  padding: 0 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  display: inline-block;
  color: #209a76;
  float: none;
}

.order_status .cancelbtn {
  min-width: 100px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  padding: 0 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  display: inline-block;
  background: #fedddc;
  float: none;
  color: #f81b17;
}

.cancelbtn:hover {
  background: #fed1d1;
}

.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  background-color: #fff;
  margin-top: 38px;
  border-radius: 10px;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}
::v-deep .el-pagination {
  display: flex;
  align-items: center;
}

.page_sign {
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.page_sign input {
  width: 45px;
  height: 28px;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.page_sign span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.detail {
  width: 100%;
  padding: 12px 254px 10px 290px;
  position: relative;
  background: #f9f9fa;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.pro {
  width: 1220px;
  padding: 20px 15px 20px 15px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #cccccc;
  margin-top: -1px;
  margin-left: -250px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.pro_img {
  width: 60px;
  height: 60px;
  display: block;
  float: left;
  margin-top: 15px;
}

.pro_detail {
  width: 450px;
  height: 90px;
  float: left;
  margin-left: 50px;
  padding-top: 3px;
}

.pro_name {
  width: 100%;
  height: 40px;
  line-height: 50px;
  word-wrap: break-word;
  font-size: 14px;
  color: #585858;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  /* margin-bottom: 16px; */
  /* background: orange; */
  margin-top: 16px;
}

.pro_size {
  width: 100%;
  line-height: 65px;
  font-size: 14px;
  color: #858585;
}

.pro_size span {
  color: #ff7768;
  float: right;
  cursor: pointer;
}

.Spec {
  display: inline-block;
  height: 90px;
  font-size: 14px;
  line-height: 90px;
  color: #858585;
  margin-left: 250px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.pro_money {
  height: 90px;
  font-size: 16px;
  line-height: 90px;
  float: right;
  color: #858585;
  margin-right: 180px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.cancel .pro_img {
  filter: grayscale(50%);
}

.cancel .productListProductNumber .cheapPrice {
  color: #c9c9ca;
  text-decoration: line-through;
}

.cancel .pro_size {
  color: #c6c6c6;
}

.cancel .pro_size span {
  color: #ff7768;
}

.cancel .pro_money {
  color: #c6c6c6;
}

.infobox {
  width: 100%;
  background: #f9f9fa;
  color: #585858;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.info_title {
  width: 100%;
  height: 40px;
  /*background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);*/
  line-height: 80px;
  font-size: 16px;
  padding: 0 42px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.info_title .save {
  width: auto;
  height: 40px;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  /*position: absolute;
  top: 50px;
  right: 90px;*/
}

.info_title .save:hover {
  background: #8ce580;
  color: #fff;
}
.Save_btn {
  text-align: center;
  padding-bottom: 35px;
}
.options {
  width: 100%;
  overflow: hidden;
  padding-left: 75px;
  padding-top: 24px;
  padding-bottom: 8px;
  position: relative;
}

.option {
  width: 555px;
  height: 42px;
  float: left;
  margin-bottom: 16px;
  font-size: 18px;
  color: #898989;
  line-height: 42px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.inputname {
  width: 235px;
  height: 42px;
  padding-right: 42px;
  font-size: 14px;
  text-align: left;
  float: left;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-left: 80px;
}

.option .inputbox .countrys {
  height: 35px !important;
}

::v-deep .el-input.is-disabled .el-input__inner {
  /* background: orangered; */
  margin-right: 75px;
  background: none;
  color: #585858;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-form-item__error {
  width: 290px;
  padding-right: 42px;
  margin-left: 250px;
  margin-top: -5px;
  text-align: left;
}

.inputbox {
  width: 300px;
  height: 35px;
  border: 1px solid #878787;
  float: left;
  color: #585858;
  text-align: center;
  border-radius: 21px;
  padding: 5px 20px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.inputbox input,
::v-deep .el-input__inner,
::v-deep .el-input {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 30px;
  background: none;
  color: #585858;
  padding: 0;
  border: none;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.Member_exclusive {
  text-align: center;
  margin-top: -30px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #4169e1;
  font-size: 14px;
}

.pay_left {
  text-align: right;
}

.recharge {
  width: 70%;
  height: 100%;
  border: 2px solid #409efe;
  border-radius: 50px;
  text-align: center;
  font-size: 16px;
  color: #4c8bf5;
  line-height: 44px;
  cursor: pointer;
  margin-left: 22px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
}

.paybtn {
  width: 70%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  background: #474882;
  line-height: 48px;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
}

.paybtn:hover {
  background: #67689a;
  color: #fff;
}

::v-deep .el-pager li {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 查询订单对话框 */
.DialogBoxOrder {
  width: 420px;
  height: 60px;
  background: #aeaeae;
  z-index: 21474899 !important;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: 90px;
  left: 180px;
  font-size: 14px;
}

.DialogBoxOrder p {
  /*color: #0000EE;*/
  color: #fff;
  margin-left: 30px;
  margin-top: 5px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.DialogBoxOrder::before {
  content: '';
  position: absolute;
  bottom: 28%;
  left: -10px;
  width: 22px;
  height: 22px;
  margin-top: -10px;
  background: inherit; /*自动继承父元素的背景*/
  transform: rotate(45deg);
  font-size: 14px;
}

.BackTop {
  /*border-radius: 50px;
  background: #15104B;*/
  position: fixed;
  bottom: 0px;
  right: 160px;
  cursor: pointer;
}

.BackTop img {
  width: 35px;
  height: 35px;
}
/* From表单 */
.Form_big_box {
  display: flex;
}
.LeftForm {
  margin-top: 30px;
  margin-left: 180px;
}
.RightForm {
  margin-top: 30px;
  margin-left: 80px;
}
.contact_name_input {
  display: flex;
}
.REQUIRED {
  color: red;
  margin-left: 5px;
}
.contact_name_words p {
  font-size: 14px;
}
.order_user_Contact {
  width: 300px;
}
.order_user_Contact >>> .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 10px;
  height: 35px;
}
.PleaseSelect_Country >>> .el-select {
  border: 1px solid #ccc;
  height: 35px;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 73px;
  width: 300px;
}
.contact_Province_input {
  display: flex;
}
.contact_Province_words p {
  font-size: 14px;
}
.order_user_Province {
  width: 300px;
}
.order_user_Province >>> .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 73px;
  height: 35px;
}
.contact_Tel_input {
  display: flex;
}
.contact_Tel_words p {
  font-size: 14px;
}
.order_user_TelePhone {
  width: 300px;
}
.order_user_TelePhone >>> .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 10px;
  height: 35px;
}
.TrackingNo {
  display: flex;
}
.orders_TrackingNo_words p {
  font-size: 14px;
}
.order_TrackingNumber {
  padding-left: 20px;
  margin-left: 40px;
  height: 35px;
  width: 300px;
  border-radius: 50px;
  font-size: 14px;
}
.contact_Address_input {
  display: flex;
  margin-left: 50px;
}
.orders_Address_words p {
  font-size: 14px;
}
.order_user_Address {
  width: 300px;
}
.order_user_Address >>> .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 10px;
  height: 35px;
}
.contact_Address2_input {
  display: flex;
  margin-left: 50px;
}
.orders_Address2_words p {
  font-size: 14px;
}
.order_user_Address2 {
  width: 300px;
}
.order_user_Address2 >>> .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 15px;
  height: 35px;
}
.contact_ZipCode_input {
  display: flex;
}
.orders_Post_code {
}
.orders_Post_code p {
  font-size: 14px;
}
.order_user_ZipCode {
  width: 300px;
}
.order_user_ZipCode >>> .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 53px;
  height: 35px;
}

/* Phone */
.contact_TelePhone_input {
  display: flex;
  margin-left: 50px;
}
.orders_TelePhone_words p {
  font-size: 14px;
}
.order_user_TelePhone {
  width: 300px;
}
.order_user_TelePhone >>> .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 25px;
  height: 35px;
}
/* City */
.contact_City_input {
  display: flex;
  margin-left: 50px;
}
.orders_City_words p {
  font-size: 14px;
}
.order_user_City {
  width: 300px;
}
.order_user_City >>> .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  margin-left: 40px;
  height: 35px;
}
.OrderTrackingNumber {
  width: 300px;
  height: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
}
.ShowOrderTrackingNumber {
  position: absolute;
  top: 0px;
}
</style>
