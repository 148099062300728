<template>
  <div></div>
</template>

<script>
import Cookies from "js-cookie";
export default {
    data:()=>{
        return {
            text: "Loading"
        }
    },
    created(){
      this.dispatch = this.$store.dispatch;
     /*  console.log(location.pathname, '111111111') */
        let that = this;
        let loading = that.$loading({
            lock: true,
            text: that.text,
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
        });

        // 获取授权时获取的state code
        let search = decodeURIComponent(location.search);
        if (search) {
          console.log('d=11111'.search)
            let params = {};
            search = search.substring(1).split("&");
           // console.log(search);
            search.map(item => {
                let index = item.indexOf("=");
                let key = item.substring(0, index);
                let value = item.substring(index + 1).split("#")[0];
                params[key] = value;
            });

            if(params.d != undefined){
                localStorage.setItem('MarketingCode', params.d);
                Cookies.set("MarketingCode", params.d);
                if (localStorage.getItem("MarketingCode") != undefined && localStorage.getItem("MarketingCode") != "undefined") {
                  this.dispatch("alliance/CheckShareRoleAi",{
                      MarketingCode: params.d
                    })
                    .then((res) => {
                    loading.close();
                      if (res.Result.IsCoFunder == true) {
                        window.location.href = res.Result.CoFunderShareLink
                      } else {
                        if (params.register != undefined) {
                      window.location.href = '/Register'
                    }else {
                      if(location.pathname == '/v') {
                        localStorage.setItem('fromLink', 'vn.usadrop.com');
                        window.location.href = 'https://vn.usadrop.com'
                      } else {
                        localStorage.setItem('fromLink', 'app.usadrop.com');
                        window.location.href = 'https://www.usadrop.com'
                      }
                      return
                    }
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
            }
        }else {
          that.$message("The link has expired")
          loading.text = "The link has expired";
      }
    }
}
</script>
