<template>
  <div class="pod-configure">
    <div class="design-title">
      <span>Custom product options for helping your customer to design</span>
      <el-switch v-model="canPreview" inactive-text="PREVIEW"></el-switch>
    </div>
    <div class="configure-main">
      <div class="left">
        <div class="title">Virtural options</div>
        <div
          :draggable="true"
          @click="chooseLayer(index)"
          v-for="(item, index) in configureData"
          @dragstart="handleDragStart(index)"
          @dragover.prevent
          @drop="handleDrop(index, $event)"
          :key="item.id"
          class="left-item"
          :class="choosedId === item.id ? 'active' : ''"
        >
          <img class="move" src="@/assets/imgs/detail/move.png" alt="" />
          <img class="type-pic" v-if="item.type === 'image'" :src="item.value" alt="" />
          <img class="type-pic" v-else src="@/assets/imgs/detail/pod_ic_T.png" alt="" />
          <div class="left-title">{{ item.form.title }}</div>
          <el-switch v-model="item.status" class="switch"> </el-switch>
        </div>
      </div>
      <div class="right">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1" class="collapse" title="Options personalization settings">
            <div class="personal-main" v-if="currentPersonal">
              <div v-show="currentLayParm?.type === 'image'">
                <div class="setting_title">Select positions for personalization</div>
                <div class="sub_title marTB16">
                  'Positions' tells in which place the print file is used to desian. You need to
                  select positions to enable the print file to be personalized by your customers in
                  these positions.
                </div>
              </div>
              <div
                v-show="currentLayParm?.type === 'i-text'"
                class="setting_title"
                style="margin-bottom: 10px"
              >
                Text content: text
              </div>
              <div class="positions">
                <div>Positions(Canvas/Layer)</div>
                <div>{{ currentPersonal?.positionName }}</div>
              </div>
              <div class="how" v-show="currentLayParm?.type === 'image'">
                <div class="setting_title marTB16">How to personalize?</div>
                <div class="imgchecks">
                  <el-checkbox class="checkbox" v-model="currentPersonal.canUpload"
                    >Upload image(Allow to crop image)</el-checkbox
                  >
                  <el-checkbox class="checkbox" v-model="currentPersonal.showPrintImg"
                    >Select from an print file group（Allow end-users to select when uploading
                    images）</el-checkbox
                  >
                </div>
                <div class="printimgs" v-show="currentPersonal.showPrintImg">
                  <div class="print-hover">
                    <el-checkbox v-model="currentPersonal.showPrintImgName"
                      >Show print file name on hover</el-checkbox
                    >
                    <el-button type="primary" size="small" round @click="openPrintImgs"
                      >Add</el-button
                    >
                  </div>
                  <div class="shown-print-imgs">
                    <div
                      class="print-files"
                      v-for="(img, index) in currentPersonal.printFileImgList"
                      :key="img.id"
                    >
                      <div class="img-btns">
                        <img class="print-img-url" :src="img.url" alt="" />
                        <img
                          @click="delImg(img, index)"
                          class="del showDel"
                          src="@/assets/imgs/detail/del.png"
                        />
                      </div>
                      <div class="img-name">
                        <div class="image-name-text">{{ img.name }}</div>
                        <img
                          @click="editImageName(img, index)"
                          class="icon-edit"
                          src="@/assets/imgs/detail/edit.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance">
                <div class="setting_title marTB16">Advanced options</div>
                <el-checkbox checked disabled
                  >Show custom ization data on cart/checkout</el-checkbox
                >
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2" class="collapse" title="Option basic info settings">
            <div class="personal-main configure-form" v-if="currentForm">
              <el-form ref="formRef" :model="currentForm" label-width="120px">
                <el-form-item
                  label="Option title"
                  prop="title"
                  :rules="[
                    {
                      required: true,
                      message: 'Please input your name!',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <el-input class="formInput" v-model="currentForm.title"></el-input>
                </el-form-item>
                <el-form-item label="Tooltip">
                  <el-input class="formInput" v-model="currentForm.toolTip"></el-input>
                </el-form-item>
                <el-form-item label="Help text">
                  <el-input class="formInput" v-model="currentForm.helpTxt"></el-input>
                </el-form-item>
                <el-form-item label="Popup tip">
                  <el-checkbox v-model="currentForm.showPopupTip"></el-checkbox>
                </el-form-item>
                <el-form-item label="Title">
                  <el-input class="formInput" v-model="currentForm.popTitle"></el-input>
                </el-form-item>
                <el-form-item label="Width">
                  <el-input class="formInput" v-model="currentForm.popWidth"></el-input>
                </el-form-item>
                <el-form-item></el-form-item>
              </el-form>
              <div class="fuwenben">
                <wangEnduit
                  :isClear="false"
                  :boxId="`box${currentLayParm.id}`"
                  v-model="currentForm.richText"
                ></wangEnduit>
              </div>
            </div> </el-collapse-item
        ></el-collapse>
      </div>
    </div>
    <el-dialog
      title="Change Print file Name"
      :visible.sync="dialogVisible"
      width="30%"
      class="modalDialog"
      :before-close="handleClose"
    >
      <el-input
        v-model="editImageItem.name"
        class="formInput"
        placeholder="Please input image name"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="dialogVisible = false">Cancel</el-button>
        <el-button round type="primary" @click="confirmEditImgName">Confirm</el-button>
      </span>
    </el-dialog>
    <PrintGroupDialog
      :showDialogVisible.sync="dialogImgVisible"
      @addSelectedImgs="addSelectedImgs"
    ></PrintGroupDialog>
  </div>
</template>
<script>
import wangEnduit from '@/components/wangEnduit'
import PrintGroupDialog from './PrintGroupDialog.vue'

export default {
  components: {
    wangEnduit,
    PrintGroupDialog
  },
  props: {
    configWholeData: {
      type: Object,
      required: true
    },
    activeScenesIndex: {
      type: Number,
      required: true
    }
  },
  watch: {
    configureData: {
      immediate: true,
      deep: true,
      handler(n, o) {
        this.$emit('updateData', n)
      }
    },
    canPreview(n, o) {
      this.$emit('updatePreview', n)
    },
    activeScenesIndex() {
      this.currentActiveIndex = 0
    },
    configWholeData: {
      immediate: true,
      deep: true,
      handler(n, o) {
        this.canPreview = n.canPreview
        this.configureData = n?.options || [{}]
        if (this.configureData.length) {
          this.choosedId = this.configureData[this.currentActiveIndex].id
          this.currentForm = this.configureData[this.currentActiveIndex].form
          this.currentPersonal = this.configureData[this.currentActiveIndex].personInfo
          this.currentLayParm = {
            id: this.configureData[this.currentActiveIndex].id,
            type: this.configureData[this.currentActiveIndex].type
          }
        }
      }
    }
  },
  data() {
    return {
      currentActiveIndex: 0,
      choosedId: '',
      dialogImgVisible: false,
      dialogVisible: false,
      activeNames: ['1', '2'],
      configureData: [],
      canPreview: false,
      designData: this.configWholeData,
      currentForm: null,
      currentPersonal: null,
      currentLayParm: null,
      startIndex: 0,
      editImageItem: {}
    }
  },

  created() {
    // this.initData()
    // if (this.configureData.length) {
    //   this.configureData[0].choosed = true
    // }
  },
  methods: {
    initData() {
      this.canPreview = this.configWholeData.canPreview
      this.configureData = this.designData?.options || []
      if (this.configureData.length) {
        this.choosedId = this.configureData[0].id
        this.currentForm = this.configureData[0].form
        this.currentPersonal = this.configureData[0].personInfo
        this.currentLayParm = {
          id: this.configureData[0].id,
          type: this.configureData[0].type
        }
      }
    },
    openPrintImgs() {
      this.dialogImgVisible = true
    },
    delImg(img, index) {
      this.currentPersonal.printFileImgList.splice(index, 1)
    },
    editImageName(item, index) {
      this.editImageItem = JSON.parse(JSON.stringify(item))
      this.editImageItem.index = index
      this.dialogVisible = true
    },
    confirmEditImgName() {
      this.currentPersonal.printFileImgList[this.editImageItem.index].name = this.editImageItem.name
      this.dialogVisible = false
    },
    handleClose() {
      this.dialogVisible = false
      this.editImageItem = {}
    },
    handleDragStart(startIndex) {
      // 设置数据源的拖动起始索引
      this.startIndex = startIndex
    },

    handleDrop(targetIndex, event) {
      // 获取拖动开始时的索引
      const _startIndex = this.startIndex
      // 防止自我拖动时不进行交换
      if (_startIndex === targetIndex) return
      // 使用Vue的响应式更新列表项的位置
      const [removed] = this.configureData.splice(_startIndex, 1)
      this.configureData.splice(targetIndex, 0, removed)
    },
    // 选中图层
    chooseLayer(index) {
      this.currentActiveIndex = index
      this.configureData = this.configureData.map((item) => ({
        ...item,
        choosed: false
      }))
      this.currentForm = { ...this.configureData[index].form }
      this.choosedId = this.configureData[index].id
      this.currentPersonal = { ...this.configureData[index].personInfo }
      this.currentLayParm = {
        id: this.configureData[index].id,
        type: this.configureData[index].type
      }
    },
    // 增加选中项目
    addSelectedImgs(imgs) {
      const _imgs = []
      imgs.forEach((item) => {
        if (
          !this.currentPersonal.printFileImgList.length ||
          this.currentPersonal.printFileImgList.findIndex((file) => file.id === item.id) == -1
        ) {
          _imgs.push({
            name: item.fileName,
            id: item.id,
            url: item.imageUrl,
            type: item.imageFormat,
            size: item.imageSize,
            height: item.imageLength,
            width: item.imageWidth
          })
        }
      })
      this.currentPersonal.printFileImgList.push(..._imgs)
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
$color: #e5e5e5;
$color1: #f4f7ff;
$color2: #333333;
.modalDialog {
  ::v-deep .el-dialog {
    border-radius: 26px;
  }
}
.pod-configure {
  width: 100%;
  // height: 100%;
  font-family: Open Sans;
  .formInput {
    ::v-deep .el-input__inner {
      border-radius: 36px;
      border: 1px solid #e5e5e5;
    }
  }
  .design-title {
    width: 100%;
    height: 72px;
    font-size: 22px;
    font-weight: 600;
    padding: 0 40px;
    @include flex_center;
    border-bottom: 1px solid $color;
  }
  .configure-main {
    @include flex_center;
    align-items: flex-start;
    // height: calc(100% - 72px);
    overflow: auto;
    .left {
      width: 270px;
      display: flex;
      flex-direction: column;
      height: 100%;
      border-right: 1px solid $color;
      .title {
        height: 48px;
        padding: 0 16px;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        border-bottom: 1px solid $color;
        @include flex_center;
      }
      .left-item {
        height: 84px;
        padding: 0 14px 0 32px;
        cursor: pointer;
        border-bottom: 1px solid $color;
        background: #ffffff;
        @include flex_center;
        // &:last-child {
        //   border-bottom: none;
        // }
        .move {
          widows: 18px;
          height: 18px;
        }
        .type-pic {
          width: 60px;
          height: 60px;
          margin: 0 10px;
          min-width: 60px;
        }
        .left-title {
          color: #333333;
          width: 70px;
          font-weight: 500;
          @include ellipse;
        }
      }
      .active {
        background: $color;
      }
    }
    .right {
      width: calc(100% - 270px);
      .collapse {
        ::v-deep .el-collapse-item__header {
          background: $color1;
          padding: 0 12px 0 32px;
          font-weight: 600;
          font-size: 16px;
        }
        .personal-main {
          padding: 20px 32px 0 32px;
          .setting_title {
            font-weight: 600;
            font-size: 16px;
            color: $color2;
          }
          .sub_title {
            color: #666666;
            font-weight: 400;
            font-size: 14px;
            max-width: 900px;
          }
          .marTB16 {
            margin: 16px 0;
          }
          .positions {
            width: 900px;
            border: 1px solid $color;
            border-radius: 4px;
            div {
              height: 44px;
              padding-left: 32px;
              @include flex_center;
              &:nth-child(1) {
                background: #f4f7ff;
                font-weight: 600;
                font-size: 16px;
              }

              &:nth-child(2) {
                @extend .sub_title;
              }
            }
          }
          .how {
            .imgchecks {
              margin-bottom: 16px;
              display: flex;
              // flex-wrap: wrap;
              .checkbox {
                // width: 50%;
                color: #333333;
              }
            }
            .printimgs {
              width: 900px;
              border-radius: 4px;
              min-height: 80px;
              border: 1px solid $color;
              padding: 20px 32px;
              .print-hover {
                width: 100%;
                @include flex_center;
              }
              .shown-print-imgs {
                margin-top: 10px;
                width: 100%;
                @include flex_center;
                justify-content: flex-start;
                flex-wrap: wrap;

                .print-files {
                  width: 25%;
                  @include flex_center;
                  align-items: flex-start;
                  justify-content: flex-start;
                  margin-bottom: 16px;
                  position: relative;
                  .img-btns {
                    width: 60px;
                    height: 60px;
                    position: relative;
                    margin-right: 8px;
                    border-radius: 6px;
                    .print-img-url {
                      width: 100%;
                      height: 100%;
                      min-width: 100%;
                    }
                    .del {
                      display: none;
                      font-size: 14px;
                      cursor: pointer;
                      width: 20px;
                      height: 20px;
                      position: absolute;
                      left: calc(50% - 10px);
                      top: calc(50% - 10px);
                    }
                    &:hover {
                      .showDel {
                        display: block;
                      }
                    }
                  }

                  .del {
                    position: absolute;
                  }
                  .img-name {
                    color: #333333;
                    @include flex_center;
                    .image-name-text {
                      @include ellipse;
                      max-width: 80px;
                    }
                    .icon-edit {
                      width: 16px;
                      height: 16px;
                      cursor: pointer;
                      margin-left: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .configure-form {
    padding-right: 270px !important;
  }
}
</style>
