<template>
  <div class="particulars">
    <div class="Filter">
      <div class="paymentOrder_search">
        <div class="orderNo_style">
          <div class="orderNo_input">
            <el-input clearable="" placeholder="Design Number" v-model="orderNumber">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
        <div class="time_style">
          <div class="time_word">
            <p>{{ $t('PaymentOrder.Date') }}</p>
          </div>
          <div class="time_selectTime">
            <el-date-picker
              class=""
              type="daterange"
              value-format="yyyy-MM-dd"
              v-model="designTime"
              :range-separator="$t('orders.to')"
            >
            </el-date-picker>
          </div>
        </div>
        <button class="search_btn comBtn btn-bg0 min-wid200" @click="search">Search</button>
        <div class="el_menu" trigger="click">
          <el-dropdown>
            <p class="search_btn comBtn btn-bg0">
              Batch Operations <i class="el-icon-caret-bottom" color="#fff"></i>
            </p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span class="Walletword" @click="releaseFn()">Publish</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span class="Walletword" @click="deleteFn()">Delete</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <button class="search_btn comBtn btn-bg0 min-wid200" @click="addNewDesign">
          Add new design
        </button>
      </div>
    </div>
    <div class="pod_list">
      <template>
        <el-table
          ref="multipleTable"
          :data="tableData"
          class="source-tab"
          row-key="designNo"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" :show-overflow-tooltip="false"></el-table-column>
          <el-table-column align="center" width="400px" label="Product">
            <div slot-scope="scope" class="content">
              <el-image
                style="width: 100px; height: 100px"
                :preview-src-list="[getMainImg(scope.row, 'productImages')]"
                :src="getMainImg(scope.row, 'productImages')"
                alt=""
              ></el-image>
              <div class="description">
                <div class="title">Title:&nbsp;{{ getTitleEn(scope.row) }}</div>
                <div class="info">SpuCode:&nbsp;{{ scope.row.spuNo }}</div>
              </div>
            </div>
          </el-table-column>
          <el-table-column prop="name" align="center" label="Template">
            <div slot-scope="scope">
              <div class="template_img">
                <el-image
                  style="width: 100px; height: 100px"
                  :preview-src-list="[getMainImg(scope.row, 'effectImages')]"
                  :src="getMainImg(scope.row, 'effectImages')"
                  alt=""
                ></el-image>
              </div>
            </div>
          </el-table-column>
          <el-table-column align="center" prop="storeName" label="Store" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="Time" show-overflow-tooltip>
            <div slot-scope="scope" class="operate_class">
              <p>{{ scope.row.createTime.split(' ')[0] }}</p>
              <p>{{ scope.row.createTime.split(' ')[1] }}</p>
            </div>
          </el-table-column>
          <el-table-column align="center" prop="creator" label="Status" show-overflow-tooltip>
            <div slot-scope="scope" class="operate_class">
              <span>{{ publishStatus[scope.row.publishState || 0] }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            prop="creator"
            label="Connect Status"
            show-overflow-tooltip
          >
            <div slot-scope="scope" class="operate_class">
              <span>{{ connectState[scope.row.connectState || 0] }}</span>
            </div>
          </el-table-column>
          <el-table-column align="center" label="Operate" show-overflow-tooltip>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: flex;
                gap: 20px;
              "
              slot-scope="scope"
            >
              <div class="operate_class">
                <template>
                  <p
                    v-if="[0, 3].includes(scope.row.connectState)"
                    style="color: #3f6aff"
                    @click="connectFn(scope.row)"
                  >
                    Connect
                  </p>
                </template>
                <template v-if="scope.row.publishState === 0 || scope.row.publishState === 3">
                  <p style="color: #3f6aff" @click="releaseFn(scope.row)">Publish</p>
                  <p style="color: #3f6aff" @click="editFn(scope.row)">Personalize</p>
                </template>
                <template v-else>
                  <p style="color: #3f6aff" @click="editFn(scope.row)">Personalize</p>
                </template>
              </div>
              <div>
                <el-popover popper-class="podPop" placement="right" trigger="click">
                  <div class="operate_class hide_btn">
                    <p
                      class="hoverAc"
                      v-if="scope.row.publishState === 0 || scope.row.publishState === 3"
                      @click="personalize(scope.row)"
                    >
                      Edit
                    </p>
                    <p
                      class="hoverAc"
                      v-if="scope.row.publishState === 0 || scope.row.publishState === 3"
                      @click="deleteFn(scope.row.designNo)"
                    >
                      Delete
                    </p>
                    <p class="hoverAc" @click="duplicateFn(scope.row)">Duplicate</p>
                  </div>
                  <i
                    style="cursor: pointer; color: #3f6aff"
                    slot="reference"
                    class="el-icon-more"
                  ></i>
                </el-popover>
              </div>
            </div>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <div class="pagebox">
        <PagePagination
          :total="pageInfo.total"
          :page-size="pageInfo.pageSize"
          :current-page="pageInfo.pageNum"
          @current-change="pageChange"
        />
      </div>
    </div>
    <storeDialog
      :show-dialog.sync="showStoreDialog"
      @confirmStoreId="confirmStoreId"
      @confirmProductId="confirmProductId"
      :isConnect="isConnect"
    ></storeDialog>
  </div>
</template>

<script>
import storeDialog from '../components/storeDialog.vue'
import PagePagination from '@/components/PagePagination.vue'
import {
  getPageDesign,
  delPodTemplate,
  publishShopify,
  copyDesign,
  convertToPodProduct
} from '../api.js'
export default {
  //import引入的组件
  components: {
    PagePagination,
    storeDialog
  },
  data() {
    return {
      tableData: [],
      showStoreDialog: false,
      multipleSelection: [],
      currentIdList: [], // 当前选中的发布设计集合
      orderNumber: '',
      designTime: [],
      publishStatus: ['Unpublished', 'Published', 'Publishing', 'failed'],
      connectState: ['unConverted', 'Converted', 'Converting', 'failed'],
      isConnect: false,
      pageInfo: {
        total: 0,
        pageSize: 8,
        pageNum: 1
      }
    }
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData()
  },
  //方法集合
  methods: {
    getTitleEn(row) {
      return row.goodsTransList
        ? row.goodsTransList.find((lang) => lang.country === 'en')?.subjectTrans
        : ''
    },
    getMainImg(row, key) {
      if (row[key]) {
        return JSON.parse(row[key])?.[0]
      } else {
        return 'http://eshop-bucket.hzpdex.com/web/1719902450_failedImg.png'
      }
    },
    search() {
      this.pageInfo.pageNum = 1
      this.getData()
      console.log('搜索条件', this.orderNumber, this.designTime)
    },
    // 发布设计到shopify
    releaseFn(row) {
      this.isConnect = false
      if (!row) {
        if (!this.multipleSelection.length) {
          this.$message.warning('Please select at least one piece of data')
          return
        } else {
          this.currentIdList = this.multipleSelection.map((item) => ({
            designNo: item.designNo,
            spuNo: item.spuNo,
            productBaseNo: item.productBaseNo
          }))
        }
      } else {
        this.currentIdList = [
          {
            designNo: row.designNo,
            spuNo: row.spuNo,
            productBaseNo: row.productBaseNo
          }
        ]
      }
      this.isConnect = false
      this.showStoreDialog = true
    },
    // 将商品链接到shopify
    connectFn(row) {
      this.isConnect = true
      this.showStoreDialog = true
      this.currentIdList = [
        {
          designNo: row.designNo,
          spuNo: row.spuNo,
          productBaseNo: row.productBaseNo
        }
      ]
    },
    // 拷贝
    async duplicateFn(row) {
      try {
        await copyDesign({ designNo: row.designNo }).then((res) => {
          if (res.code === 0) {
            this.$message.success('Duplicate successful')
            this.getData()
          } else {
            this.$message.error('Duplicate failed')
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$message.error('Duplicate failed')
      }

      // 调接口
    },
    // 删除设计
    deleteFn(id) {
      if (!id) {
        if (!this.multipleSelection.length) {
          this.$message.warning('Please select at least one piece of data')
          return
        }
        const ids = this.multipleSelection.map((item) => item.designNo)
        this.confirmDelete(ids)
      } else {
        const ids = [id]
        this.confirmDelete(ids)
      }
      console.log(this.multipleSelection, id)
    },

    // 确认删除
    confirmDelete(ids) {
      this.$confirm('Are you sure to delete the design？, Do you want to continue?', 'Tip', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        // const ids = this.multipleSelection.map((item) => item.designNo)
        let loading = this.$loading({
          lock: false,
          fullScreen: true, // 是否为全屏 Dialog
          spinner: 'el-icon-loading',
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        try {
          await delPodTemplate({ designNo: ids }).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: 'Delete successful!'
              })
              this.getData()
            }
          })
          loading.close()
        } catch (error) {
          loading.close()
        }
      })
    },
    confirmStoreId(storeInfo) {
      if (this.isConnect) {
      } else {
        publishShopify({
          spuPublishShopifyInfos: this.currentIdList,
          shopifyStoreId: storeInfo.storeId,
          platform: 2
        })
          .then((res) => {
            if (res.code === 0) {
              this.showStoreDialog = false
              this.multipleSelection = []
              this.getData()
            } else {
              this.$message.error(res.msg || 'Failed')
            }
          })
          .catch((err) => {
            this.$message.error(err?.msg || 'Failed')
          })
      }
    },
    confirmProductId(storeInfo) {
      if (this.isConnect) {
        this.currentIdList.forEach((item) => {
          convertToPodProduct({
            designNo: item.designNo,
            productBaseNo: item.productBaseNo,
            spuNo: item.spuNo,
            shopifyStoreId: storeInfo.storeId,
            shopifyProductId: storeInfo.productId,
            platform: 2
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success('Operation successful')
              this.showStoreDialog = false
              this.multipleSelection = []
              this.getData()
            } else {
              this.$message.error('Operation failed')
            }
          })
        })
      } else {
      }
    },
    personalize(row) {
      //  打开详情个性化配置页面detail
      window.open(
        `${process.env.VUE_APP_UD_SHOP_HOST}/pod/index?did=${row.designNo}&pNo=${row.productBaseNo}&spuNo=${row.spuNo}`,
        '_blank'
      )
    },
    editFn(row) {
      this.$router.push(`/templates/detail?id=${row.designNo}&spu=${row.spuNo}`)
      // 跳转编辑器设计页面
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    pageChange(page) {
      //分页器页数改变
      this.pageInfo.pageNum = page
      this.getData()
    },
    async getData() {
      const params = {
        designNo: this.orderNumber,
        startTime: this.designTime.length ? this.designTime[0] : '',
        pageNum: this.pageInfo.pageNum,
        platform: 2,
        size: this.pageInfo.pageSize,
        endTime: this.designTime.length ? this.designTime[1] : ''
      }
      let loading = this.$loading({
        lock: false,
        fullScreen: true, // 是否为全屏 Dialog
        text: 'Loading',
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        await getPageDesign(params).then((res) => {
          if (res.code === 0) {
            this.tableData = res.body?.records || []
            this.pageInfo.total = res.body?.total
          }
        })
        loading.close()
      } catch (error) {
        Message.error({
          message: 'Server deviation~'
        })
        loading.close()
      }
    },
    addNewDesign() {
      window.open(`${process.env.VUE_APP_UD_SHOP_HOST}/podList/index`, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  font-family: 'Regular';
}
.Filter {
  width: 1320px;
  height: 70px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 70px;
  font-family: 'Regular';
  .paymentOrder_search {
    display: flex;
    .store_style {
      display: flex;
      .store_word p {
        font-size: 14px;
        margin-left: 30px;
      }
      .store_select {
        margin-left: 15px;
        ::v-deep .el-input__inner {
          width: 260px;
          border-radius: 30px;
        }
      }
    }
    .orderNo_style {
      display: flex;
      .orderNo_word p {
        font-size: 14px;
        margin-left: 30px;
      }
      .orderNo_input {
        margin-left: 15px;
        ::v-deep .el-input__inner {
          width: 240px;
          border-radius: 30px;
        }
      }
    }
    .time_style {
      display: flex;
      .time_selectTime {
        margin-left: 15px;
      }
      ::v-deep .el-range-editor.el-input__inner {
        width: 300px;
        border-radius: 30px;
      }
      ::v-deep .el-date-editor .el-range-separator {
        width: 15%;
      }
      .time_word p {
        font-size: 14px;
        margin-left: 30px;
      }
    }
  }
  .search_btn {
    margin-left: 50px;
    margin-top: 15px;
  }
}
.pod_list {
  width: 1320px;
  border-radius: 10px !important;
  overflow: hidden;
  padding-bottom: 32px;
  margin-top: 30px;
  position: relative;
}
.pagebox {
  width: 100%;
  height: 70px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  .page-box {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
}
.el-dropdown-menu__item--divided:before {
  margin: 0;
}
.source-tab {
  width: 100%;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  ::v-deep .el-table th,
  ::v-deep .el-table tr {
    height: 70px;
    font-weight: 500;
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding-left: 24px;
    img {
      width: 100px;
      height: 100px;
    }
    .description {
      flex: 1;
      min-width: 0;
      padding-left: 8px;
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 14px;
      .title {
        max-height: 36px;
        width: 100%;
        line-height: 18px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 定义文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        color: #444444;
      }
      .info {
        font-size: 14px;
        color: #999999;
      }
    }
    .result {
      justify-content: space-between;
    }
  }
}
.template_img img {
  width: 100px;
  height: 100px;
}
.operate_class {
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    cursor: pointer;
  }
  .tc {
    text-align: left;
  }
  .hoverAc {
    &:hover {
      color: rgb(63, 106, 255);
    }
  }
}
.hide_btn {
  // align-items: flex-start;
  p {
    margin: 5px 0;
  }
}
</style>

<style type="text/css">
.podPop {
  min-width: 50px !important;
}
</style>
