var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.LinkYouStoreBox
    ? _c("div", { staticClass: "bind_store_box" }, [
        _c("div", { staticClass: "bind_store_content_box" }, [
          _c("div", { staticClass: "close_btn_icon" }, [
            _c("i", {
              staticClass: "el-icon-circle-close",
              on: { click: _vm.CloseMyStore },
            }),
          ]),
          _c("div", { staticClass: "header_Copywriting" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("MemberBindBox.LinkStore")))]),
          ]),
          _c("div", { staticClass: "Dashed_line" }),
          _vm._m(0),
          _c("div", { staticClass: "bottom_btn_copywriting" }, [
            _c(
              "div",
              {
                staticClass: "link_my_store_btn",
                on: { click: _vm.LinkMyStoreBtn },
              },
              [
                _vm._m(1),
                _c("p", [_vm._v(_vm._s(_vm.$t("MemberBindBox.LinkMyStore")))]),
              ]
            ),
            _c("div", { staticClass: "bottom_Copywriting" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("MemberBindBox.BindStore")))]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Cneter_content_box" }, [
      _c("div", { staticClass: "left_img_box" }, [
        _c("img", {
          attrs: {
            src: require("../assets/imgs/SideBar/computer@2x.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "center_img_box" }, [
        _c("img", {
          attrs: {
            src: require("../assets/imgs/SideBar/convert206@2x.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "right_img_box" }, [
        _c("img", {
          attrs: {
            src: require("../assets/imgs/SideBar/UDLogo208@2x.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../assets/imgs/SideBar/btnconvert@2x.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }